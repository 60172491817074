.teamSettings {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-form {
        padding: 3.8rem;
        align-self: center;
        display: flex;
        gap: 98px;
        padding-bottom: 36px;
        border-bottom: 1px solid rgba(195, 195, 195, 0.80);
        &-body {
            h3 {
                background: #EEEEEE;
                border-radius: 15px;
                padding: 8px 16px;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                display: block;
                margin-bottom: 18px;
            }
            &-table {
                width: 100%;
                background: transparent;
                &-actions {
                    &-button {
                        border: 0;
                        background: transparent;
                        &[disabled] {
                            cursor: not-allowed;
                        }
                        &:not([disabled]) {
                            cursor: pointer;
                        }
                        svg {
                            width: 0.875rem;
                            height: 0.875rem;
                        }
                    }
                }
                th {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                }
                td {
                    color: #000;
                    font-size: 0.875rem;
                    font-weight: 400;
                }

            }
        }
        &-field {
            position: relative;
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 16px;
            padding: 0 20px;
            label {
                flex-basis: 50%;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
            }
            input {
                background: #FFFFFF;
                border: 1px solid #C3C3C3;
                border-radius: 15px;
                flex-basis: 50%;
                min-width: 263px;
            }
            .eye-icon {
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 20px;
                height: auto;
                fill: #ccc;
            }            
        }
        &-footer {
            padding: 22px 78px 22px 78px;
            text-align: center;
            &-button {
                background: rgba(0, 0, 0, 0.85);
                border-radius: 37px;
                padding: 8px 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
            }
        }
        &-bottom {
            padding: 3.8rem;
            border: 0;
            margin-top: 47px;
        }
    }
    .email-select-input {
        display: inline-block;
        width: 300px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
      }
      
      .emails-container {
        display: flex;
        flex-wrap: wrap;
      }
      
      .email-tag {
        display: inline-flex;
        align-items: center;
        background-color: #f1f1f1;
        border-radius: 4px;
        padding: 4px;
        margin-right: 4px;
        margin-bottom: 4px;
      }
      
      .remove-button {
        background-color: transparent;
        border: none;
        color: #999;
        cursor: pointer;
        font-size: 12px;
        margin-left: 4px;
      }
    .currentUser {
        font-weight: bold;
    }
    &-modal {
        display: block;
        background: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2rem 4rem;
        max-height: 10rem;
        text-align: center;
        border-radius: .5rem;
        p {
            margin-bottom: 2rem;
        }
        button {
            margin-right: 1rem;
        }
    }
    .background-modal {
        position: fixed;
        z-index: 9;
        background: black;
        opacity: .2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.errorMessage {
    font-weight: bold;
    color: red;
    text-align: center;
  }
  