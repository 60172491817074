.subscription {
  padding: 12rem 1rem 8rem 1rem;
  width: 100%;
  overflow: hidden;
  &-content {
    border-radius: 1.625rem;
    background: linear-gradient(342deg, #FFF 0%, #EBEBEB 100%); 
    &-header {
      display: flex;
      h2 {
        color: #848199;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 4rem;
        max-width: 46rem;
      }
      &-toggle {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        &-label {
          color: #000;
          font-size: 0.875rem;
          margin: 0 0.5rem;
        }
      }
    }
    &-plans {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4rem;
    }
    &-customPlan {
      border-radius: 0.9375rem;
      display: flex;
      margin-top: 5rem;
      flex-wrap: wrap;
      gap: 4rem;
      &-column {
        flex: 1;
        h2 {
          color: #000;
          font-size: 1.75rem;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }
        p {
          color: #848199;
          font-size: 0.9375rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        button {
          border-radius: 1.625rem;
          background: linear-gradient(174deg, #FF42FF 0%, #000 100%) !important;
          text-align: center;
          font-size: 0.9375rem;
          font-weight: 600;
          padding: 0.5rem 3rem;
          border: 0;
        }
        ul {
          li {
            color: #848199;
            font-size: 0.9375rem;
            font-weight: 400;
          }
        }
      }
    }
    &-plan {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid  rgba(195, 195, 195, 0.8);
      flex: 1;
      h1 {
        color: #FF42FF;
        font-size: 0.9375rem !important;
        font-weight: 600;
        margin-bottom: .8rem !important;
      }
      h2 {
        color: #333;
        font-size: 2.125rem;
        font-weight: 400;
        margin-bottom: 2.25rem;
      }
      p {
        color: #848199;
        font-size: 0.9375rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }
      button {
        margin-bottom: 2rem !important;
        border-radius: 1.625rem;
        background: linear-gradient(175deg, #FF42FF 0%, rgba(255, 66, 255, 0.00) 100%);
        padding: 0.5rem 1.5rem;
        color: var(--white, #FFF);
        text-align: center;
        font-size: 0.9375rem;
        font-weight: 600;
        padding: 0.5rem 3rem;
        border: 0 !important;
        &.isfree {
          margin-bottom: 4rem !important;
        }
        svg {
          margin-left: 0.5rem;
        }
      }
      &-description {
        min-height: 67px;
        margin-bottom: 2.25rem;
      }
      &-details {
        color: #848199;
        padding: 1px;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-left: 5px;
        line-height: 1;
        position: relative;
        top: 5px
      }
    }
  }
  .special-height{
    height: 36rem;
  }
  .modal-pro {
    height: 20rem;
  }
  &-modal {
    position: absolute;
    background-color: var(--gray-light);
    border-radius: 2rem;    
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 3rem 4rem;
    display: block;
    max-width: 500px;
    height: 30rem;
    overflow-y: auto;
    h2 {
      margin-bottom: 1rem;
      font-weight: bold;
    }
    p {
      margin-top: 1rem;
    }
    label {
      color: #000;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.9375rem;
      background: #EEE;
      width: 100%;
      display: block;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
    }
    input {
      border-radius: 0.9375rem;
      border: 1px solid #C3C3C3;
      background: #FFF;
      margin-bottom: 1rem;
    }
    &-buttons {
      text-align: center;
      margin-top: 2rem;
    }
    .submit {
      border-radius: 37px;
      background: rgba(0, 0, 0, 0.85);
      padding: 0.5rem 1.25rem;
      color: #FFF;
      font-size: 0.8rem;
      margin-left: 0.6rem;
      cursor: pointer;
      &:hover {
        color: #FFF;
      }
    }
    .modal-close {
      &::before, &::after{
        background-color: black !important;
      }
    }
    .reset-btn {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .modal-background {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }
  .active-plan {
    padding: 0;
    border-radius:0.9375rem;
    border: 1px solid #C3C3C3;
    background: #F6F6F6;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 2px 8px 19px 0px rgba(0, 0, 0, 0.05), 9px 33px 34px 0px rgba(0, 0, 0, 0.04), 21px 74px 46px 0px rgba(0, 0, 0, 0.03), 37px 132px 55px 0px rgba(0, 0, 0, 0.01), 59px 206px 60px 0px rgba(0, 0, 0, 0.00);
    .title {
      text-align: center;
      color: #FAFAFA;
      background: #333;
      width: 100%;
      padding: 0.25rem;
      border-radius: 0.9375rem 0.9375rem 0rem 0rem;
      border: 1px solid #333;
      background: #333;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 2px 8px 19px 0px rgba(0, 0, 0, 0.05), 9px 33px 34px 0px rgba(0, 0, 0, 0.04), 21px 74px 46px 0px rgba(0, 0, 0, 0.03), 37px 132px 55px 0px rgba(0, 0, 0, 0.01), 59px 206px 60px 0px rgba(0, 0, 0, 0.00);
    }
    &-content {
      padding: 2.8rem 2.25rem 2.37rem 4.19rem;
    }
    &-buttons {
      display: flex;
      gap: 0.75rem;
    }
    &-cancel {
      border-radius: 1.625rem;
      background: linear-gradient(116deg, #FF4242 -24.33%, rgba(255, 66, 66, 0.00) 167.21%);
      color: var(--White, #FFF);
      padding: 0.4375rem 1.5625rem;
      margin-top: 2rem;
      margin-bottom: 0 !important;
      cursor: pointer;
      font-size: 0.625rem;
      svg {
        margin-left: 0.5rem;
        width: 0.3rem;
        height: 0.3rem;
      }
    }
    &-change-plan {
      border-radius: 1.625rem;
      background: linear-gradient(126deg, #FF42FF -44.81%, rgba(255, 66, 255, 0.00) 197.13%);
      color: var(--White, #FFF);
      padding: 0.4375rem 1.5625rem;
      margin-top: 2rem;
      margin-bottom: 0 !important;
      cursor: pointer;
      font-size: 0.625rem;
      svg {
        margin-left: 0.5rem;
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
  .small-modal {
    max-height: 200px;
  }
  .modal-button {
    margin-top: 20px;
    border: 0;
  }
  &-switch {
    width: 100%;
    max-width: 48.6875rem;
    max-height:  20.9375rem;
    h1{
      color: #000;
      font-size: 1.25rem;
      border-bottom: 1px solid rgba(195, 195, 195, 0.80);
      padding-bottom: 0.7rem;
    }
    p {
      color: #848199;
      font-size: 0.9375rem;
    }
    .modal-button {
      border-radius: 0.9375rem;
      background: #333;
      color: #FFF;
      font-size: 0.875rem;
      font-weight: 400;
      width: 100%;
      margin-top: 3.5rem;
      span {
        font-weight: 600;
      }
    }
  }
  &-feedback {
    button {
      margin-top: 10px !important;
    }
  }
}
.active-subscription {
  padding: 2rem;
  width: 100%;
  h1 {
    color: #000;
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  .button {
    border-radius: 2.3125rem;
    background: rgba(0, 0, 0, 0.85);
    color: #FFF;
    font-size: 0.875rem;
    border: 0 !important;
    margin-top: 1rem;
  }
  ul {
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .subscription-content {
    margin-left: -2rem;
    margin-right: 0;
    width: calc(100% + 2rem) !important;
    padding-top: 6.9rem !important;
  }
  .subscription-content-header h2 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .active-subscription {
    padding: 2rem 4rem 0 4rem;
    width: 100%;
  }
  .subscription-content-plan {
    border-left: 1px solid rgba(195, 195, 195, 0.8);
    margin-bottom: 0;
    border-bottom: 0;
    &> div {
      padding: 0 3rem;
    }
  }
  .subscription-content-plans {
    border-right: 1px solid rgba(195, 195, 195, 0.8);
  }
  .subscription-content-customPlan {
    padding: 3rem 7rem;
    border: 1px solid rgba(255, 66, 255, 0.40);
    background: linear-gradient(122deg, #FFF 0%, #F1F0F0 100%);
    margin-bottom: 4rem;
  }
}
.transaction-details {
  width: 600px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 20px;
  margin-top: 20px;
  h2 {
    text-align: center;
  }
  .details-section {
    margin-top: 20px;
    h3 {
      color: #495057;
      font-size: 1.2rem;
      border-bottom: 1px solid #ced4da;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
    }
    li {
      margin-bottom: 10px;
    }
  }
}


@media (max-width: 768px) {
  .subscription {
    .header-left {
      display: none;
    }
  }
  .subscription-content-header {
    flex-wrap: wrap;
    h2 {
      margin: 0;
      font-size: 0.875rem;
      color: #000;
    }
  }
  .subscription-content {
    padding: 0;
    background: transparent;
  }
  .subscription-content-plan, .subscription-content-customPlan  {
    padding: 1.25rem 1.94rem;
    border-radius: 0.625rem;
    border: 1px solid #C3C3C3;
    background: #F0F0F0;
  }
  .subscription-content-customPlan {
    margin-top: 0;
  }
}


@media (min-width: 768px) {
  .subscription {
      width: calc(100% - 15.6rem);
      padding: 0;
      &-container {
          padding: 0 2rem 2rem 2rem;
      }
  }
}
