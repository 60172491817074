@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;400;600;700&display=swap');
@font-face {
  font-family: 'PP Mori';
  src: local('PP Mori'), url(./assets/newDesign/font/ppmori/PPMori-Regular.otf) format('otf');
}
@font-face {
  font-family: 'PP Mori';
  font-weight: 600;
  src: local('PP Mori'), url(./assets/newDesign/font/ppmori/PPMori-SemiBold.otf) format('otf');
}
@font-face {
  font-family: 'PP Mori';
  font-weight: 100;
  src: local('PP Mori'), url(./assets/newDesign/font/ppmori/PPMori-Extralight.otf) format('otf');
}
@font-face {
  font-family: 'PP Mori';
  src: local('PP Mori'), url(./assets/newDesign/font/ppmori/PPMori-Regular.eot) format('truetype');
}
@font-face {
  font-family: 'PP Mori';
  src: local('PP Mori'), url(./assets/newDesign/font/ppmori/PPMori-Regular.woff) format('truetype');
}
:root {
    --primary: #0A0A0A;
    --secondary: #FF42FF;
    --tertiary: #13D5FF;
    --text-color-over-light: --primary;
    --text-color-over-light-secondary: rgba(0, 0, 0, .5);
    --text-color-over-dark-primary: #333333;
    --text-color-over-dark-primary-90:#333333E5;
    --text-color-over-dark-primary-4: rgba(255,255,255,0.4);
    --text-color-over-dark-secondary: rgba(255, 255, 255, .6);
    --background-color-dark: #cdcdcd;
    --background-color-darker: #313131;
    --background-color-light: #EEEEEE;
    --background-color-lighter: #F1F0F0;
    --anchor-text-color: #0A0A0A;
    --primary-20: rgba(0, 167, 245, 0.20);
    --primary-05: rgba(0, 167, 245, 0.05);

    --accent: #21435F;
    --border-light: #e2e2e2;
    --border-dark: #d4d0d0;
    --green: #13FFC6;

    --red-messages: #c2344a;
    --dark-red-messages: #FA6B6B;
    --light-red-messages: #FFE2E7;
    --red-messages-60: rgb(222, 66, 91, .6);
    --white: #ffffff;
    --gray-light: #fafafa;
    --gray: #5A5A5A;
    --gray-lighter: #C3C3C3;
    --black: #000000;
    --deep-red: #b22222; /* Feel free to change the color */
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Manrope', sans-serif;
    font-size: 100%;
    color: var(--text-color-over-light);
    overscroll-behavior: none;
}

.modal-background {
    background-color: rgba(10,10,10,.30);
    position: fixed;
}
#modal-root {
  z-index: 9;
}
.background-show-modal {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  position: absolute;
}


/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: 
	  opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out,
		-webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	-moz-transition:    
		opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out,
		-moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
	transition:         
		opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out,
		transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform:    translate3d(0, 0, 0);
  transform:         translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  background-color: #CCCCCC;
  color: #181818;
  content: attr(data-tooltip);
  font-size: 12px;
  line-height: 1.2;
  border-radius: 10px;
  text-align: center;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  top: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-top: -6px;
  border-top-color: #CCCCCC;
  transform: rotate(180deg) !important;
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -60px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(5px);
  -moz-transform:    translateY(5px);
  transform:         translateY(5px); 
}


.modal-hidde {
  max-height: 100vh;
  max-width: 100vw;
}

.modalOpened{
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}



.enter-flipando {
  background-image: url("./assets/login-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  min-height: 100vh;
}


@media (min-width: 768px) {
  .flipando {
    display: flex;
    width: 100%;
    > nav {
        flex: 0 0 15.6rem;
    }
    > main {
        flex: 1;
    }
  }
  .enter-flipando {
    background-image: url("./assets/login-desktop.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .navbar {
      justify-content: center;
      .navbar-item {
        font-size: 1rem;
      }
    }
  }
}