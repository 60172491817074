
.language-switcher {
    display: flex;
    padding: 0 1.56rem;
    align-items: center;
    svg {
        margin-right: 0.5rem;
    }
    .language-button {
        background: transparent;
        border: 0;
        outline: 0;
        padding: 0;
        color: var(--Negro, #000);
        font-family: Manrope, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        cursor: pointer;
        padding: 0 .5rem;
        &.active {
            text-decoration: underline;
        }
    }
}