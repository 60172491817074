.carousel {
    margin-left: -1rem;
    margin-right: -1rem;
    .appCard {
        cursor: pointer;
    }
    .carousel-spacing {
        padding: .3rem .5rem;
    }
}

@media (min-width: 768px)  {
    .carousel {
        margin: 0;
    }
}