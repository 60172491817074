.showAllApps {
        &-topbar {
        position: fixed;
        top: 10.8rem;
        background: white;
        height: 1rem;
        width: 100%;
        margin-left: -1rem;
        z-index: 10;
    }
    &-header {
        background: white;
        position: fixed;
        width: 100%;
        top: 10.8rem;
        z-index: 10;
        background: white;
        margin-left: -1rem;
        padding: 1rem;
        .searcher {
            background: var(--Gris, #D4D4D4);
        }
    }

    &-apps {
        &-tabs {
            display: flex;
            justify-content: center;
            margin-bottom: 1.25rem;
            gap: .62rem;
        }
        &-card {
            margin-bottom: 1rem;
            padding-left: .8rem;
            padding-right: .8rem;
        }
    }
}

.showAllApps-apps-tabs-button {
    color: var(--negro-45, rgba(0, 0, 0, 0.45));
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    border: none;
    background: none;
    cursor: pointer;
    padding: .62rem;
}

.showAllApps-apps-tabs-button.active {
    color: var(--Negro, #000);
    border-bottom: 1px solid #000;
}

@media (min-width: 768px) { 
    .showAllApps{
        &-header {
            position: relative;
            margin: 0;
            top: 0;
            background: transparent;
            padding: 0;
            z-index: 1;
        }
    }
}