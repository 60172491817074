.edit-app {
    padding: 2rem 4rem;
    width: 100%;
    &-title {
        background-color: var(--background-color-light);
        border-radius: 30px;
        padding: 38px;
        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            color: var(--black);
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: var(--gray);
        }
    }
    &-columns {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        column-gap: 1rem;
        .column {
            border: 1px solid #C3C3C3;
            border-radius: 15px;
            background-color: var(--gray-light);
            padding:2rem;
            h2 {
                background: #EEEEEE;
                border-radius: 15px;
                padding: 8px 18px;
                width: 100%;
                color: var(--black);
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 14px;
            }
            h1 {
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                color: var(--black);
                margin-bottom: 20px;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: var(--gray);
                line-height: 1.5;
                margin-bottom: 16px;
            }
            li {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                color: var(--black);
                margin-bottom: 8px;
            }
            ul {
                list-style-type: disc;
                padding-left: 20px;
            }
            code {
                font-family: monospace;
                padding: 4px;
                color: red;
            }
            
            pre > code {
                font-size: 14px;
                background-color: var(--code-bg);
                opacity: 85%;
                display: block;
                white-space: pre-wrap;
                color: var(--white);
            }
            
            pre {
                position: relative;
                background-color: var(--code-bg);
                opacity: 85%;
                padding: 10px;
                margin-bottom: 16px;
                border-radius: 4px;
                color: var(--white);
            }
        }
    }
    &-form {
        &-section:not(:first-child) {
            border-bottom: 1px solid #C3C3C3;
            padding: 25px 0;
        }
        &-section:first-child {
            border-bottom: 1px solid #C3C3C3;
            padding-bottom: 25px;
        }
        &-logic {
            margin-top: 25px;
        }
        &-field {
            padding-left: 20px;
            &-control {
                display: flex;
                margin-bottom: 17px;
            }
        }
        .field {
            display: flex;
            padding-left: 20px;
        }
        label {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--black);
            flex: 1;
            width: 40%;
        }
        input, textarea, select {
            flex: 2;
            background: #FFFFFF;
            border: 1px solid #C3C3C3;
            border-radius: 15px;
            min-width: auto;
            position: relative;
        }

    &-buttons {
        display: flex;
        flex-flow: wrap;
        justify-content: flex-end;
        gap: .85rem;
        button {
            display: flex;
            border-radius: 2.3125rem;
            padding: 0.5rem 1.25rem !important;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            font-weight: 500;
            background: none;
            cursor: pointer;
        }
        .publish, .private {
            border: 1px solid #000;
            color: #000000;
        }
        .save-changes, .discard-changes {
            background: #000;
            border-color: transparent;
            color: #FFFFFF;
        }
    }

    .add-file span {
        display: flex;
        align-items: center;
    }
    .add-file {
        background: var(--gray-light);
        border: 1px solid #9002FF;
        color: #9002FF;
        padding: 5px 17px;
        border-radius: 37px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        svg path {
            fill: #9002FF !important;
          }        
        &:hover, &:active, &:visited {
            background: #9002FF;
            color: var(--gray-light);
            svg path {
                fill: var(--gray-light) !important;
              }
        }
        svg {
            width: 14px;
            margin-right: 8px;
            path {
                fill: var(--black);
            }
        }
    } 
    .add-file-control {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    }
    .remove {
        background: transparent;
        border: 0;
        padding: 0;
        width: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 12px;
        cursor: pointer;
    }
    
    .file-info-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
      }
      
    .file-info {
        border: rgba(144, 2, 255, 0.60);
        background: rgba(144, 2, 255, 0.60);
        color: var(--gray-light);
        padding: 5px 17px;
        margin-left: 1rem;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }
      
    .delete-file-button {
        align-items: center;
        padding: 6.5px 10px;
        margin-left: 1px;
        background: rgba(144, 2, 255, 0.60);
        border: rgba(144, 2, 255, 0.60);
        cursor: pointer; 
    }
      

    &-variable-button {
        border-radius: 2px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 14px;
        padding: 3px 11px;
        background: #D4D4D4;
        color: #5A5A5A;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    &-submit-button {
        border-radius: 37px;
        padding: 8px 20px;
        background: var(--black);
        color:var(--white);
        margin-top: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        svg {
            margin-right: 8px;
        }
        &[disabled]{
            color: var(--black);
            svg {
                path {
                    fill: var(--black);
                }
            }
        }
        &:hover, &:active, &:visited {
            background: var(--white);
            color: var(--black);
            svg path {
                fill: var(--black) !important;
              }
        }
    }
    .add-variable {
        background: var(--gray-light);
        border: 1px solid var(--black);
        color: var(--black);
        padding: 5px 17px;
        border-radius: 37px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        &:hover, &:active, &:visited {
            background: var(--black);
            color: var(--gray-light);
            svg path {
                fill: var(--gray-light) !important;
              }
        }
        svg {
            width: 14px;
            margin-right: 8px;
            path {
                fill: var(--black);
            }
        }
    }
    .delete-button {
        display: flex;
        justify-content: flex-end;
    }
    .toggle-button {
        max-width: 60px !important;
    }
}

@media (min-width: 768px)  {
    .edit-app-content-frame {
        padding: 4rem;
    }
    .edit-app-columns {
        flex-direction: row;
        .column {
            padding: 20px 46px 55px 46px;
        }
    }
}