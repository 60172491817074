.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 19px;
    border-bottom: 1px solid rgba(195, 195, 195, 0.4);
    margin-bottom: 16px;
    &-left {
        align-items: center;
        form {
            margin-bottom: 0;
            max-height: 40px;
            flex: 1;
            background: var(--Gris-claro, #E8E6EA);
            border: 0;
            color: var(--negro-45, rgba(0, 0, 0, 0.45));
            font-size: 1rem;
        }
        .dropdown  {
            flex: 1;
        }
    }
    h1 {
        color: var(--black);
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 1rem;
    }
    .searcher {
        max-width: 305px;
        margin-right: 1.5rem;
    }
    .navbar-item {
        display: inline-flex;
        padding: 0.5625rem 0.75rem;
        align-items: flex-start;
        gap: 0.6875rem;
        border-radius: 1.875rem;
        border: 1px solid #C3C3C3;
        background: linear-gradient(305deg, rgba(229, 229, 229, 0.50) 3.17%, rgba(255, 255, 255, 0.50) 95.6%);
        backdrop-filter: blur(40px);
        p {
            color: #333;
            font-size: 1rem;
            font-weight: 600;
            span {
                color: rgba(51, 51, 51, 0.90);
                font-size: 0.875rem;
                font-weight: 400;
            }

        }
    }
    .logout-button {
        border: 0;
        background: transparent;
        cursor: pointer;
    }
    &-btn {
        width: 40px;
        height: 40px;
        background-color: #8787871A;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        &:hover {
            background-color: #87878766;
        }
    }
}

@media (min-width: 768px) {
    .header-left {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .dropdown  {
        display: none;
    }
    .searcher {
        margin: 0;
        width: 100%;
    }
}