.learningResources {
    width: 100%;
    padding: 12rem 1rem 1rem 1rem;

    &-title {
      color: #000;
      font-family: "PP Mori", sans-serif;
      font-size: 2.25rem;
      font-weight: 400;
      margin-bottom: 1.88rem;
    }

    &-subtitle {
        font-size: 1rem;
        font-weight: bold;
    }
    &-content {
        margin-bottom: 2rem;
        .video-row {
            display: flex;
            margin-top: 1rem;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }
    .desplegable {
        border: 1px solid #ddd;
        margin: 10px;
        padding: 10px;
        background-color: #f9f9f9;
      }
      
      .boton-desplegable {
        color: #000;
        border: none;
        padding: 10px;
        cursor: pointer;
        width: 100%;
        text-align: left;
        outline: none;
        font-size: .95rem;
      }
      
      .boton-desplegable:hover {
        background-color: #e0dfdf;
      }
      
      .contenido-desplegable {
        display: none;
        font-size: .9rem;
        margin-top: 1rem;
      }
      
      .desplegable.abierto .contenido-desplegable {
        display: block;
      }
      
}

@media (min-width: 768px) {
  .learningResources {
      width: calc(100% - 15.6rem);
      padding: 0;
      &-content {
          padding: 0 2rem 2rem 2rem;
      }
  }
}