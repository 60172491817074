.new-app {
    padding: 2rem 4rem;
    width: 100%;
    background-image: url("../../assets/newDesign/backgrounds/createbg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    &-title {
        background-color: var(--background-color-light);
        border-radius: 30px;
        padding: 38px;
        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            color: var(--black);
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: var(--gray);
        }
    }
    &-columns {
        display: flex;
        column-gap: 1rem;
        flex-direction: column;
        > :first-child {
            border-right: 1px solid rgba(255, 255, 255, 0.60);
        }
        .column {
            margin-bottom: 2rem;
            h1 {
                color: #000;
                font-family: PP Mori, sans-serif;
                font-size: 2.25rem;
                font-weight: 400;
                margin-bottom: 3rem;
            }
            
            h2 {
                color: var(--Negro, #000);
                font-family: PP Mori, sans-serif;
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: .31rem;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: var(--gray);
                line-height: 1.5;
                margin-bottom: 16px;
            }
            em {
                font-style: italic;
                font-weight: bold !important;
                color: #333;
            }
            li {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                color: var(--black);
                margin-bottom: 8px;
            }
            ul {
                list-style-type: disc;
                padding-left: 20px;
            }
            code {
                font-family: monospace;
                padding: 4px;
                color: red;
            }
            
            pre > code {
                font-size: 14px;
                background-color: var(--code-bg);
                opacity: 85%;
                display: block;
                white-space: pre-wrap;
                color: var(--white);
            }
            
            pre {
                position: relative;
                background-color: var(--code-bg);
                opacity: 85%;
                padding: 10px;
                margin-bottom: 16px;
                border-radius: 4px;
                color: var(--white);
            }          
        }
    }
    &-form {
        &-section:not(:first-child) {
            padding: 25px 0;
        }
        &-section:first-child {
            padding-bottom: 25px;
        }
        &-field {
            &-control {
                display: flex;
                margin-bottom: 17px;
            }          
        }
        &-buttons {
            display: flex;
            justify-content: flex-end;
        }            
        &-file-uploaded {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
        &-remove-file {
            background-color: #d3d3d3;
            color: #000000;
            border-radius: 50%;
            border: none;
            width: 20px;
            height: 20px;
            font-size: 12px;
            cursor: pointer;
            outline: none;
            margin-top: 3px;
            &:hover {
              background-color: #ff0000;
              color: #ffffff;
            }
        }          
        &-upload-button {
            margin-top: 1rem;
            display: flex;
            height: 31px;
            width: 100% !important;
            border-radius: 15px;
            background: #C7C7C7;
            color: #000;
            font-size: 14px;
            font-weight: 600;
            min-width: auto;
            position: relative;
            margin-bottom: 2rem;
        }
        label {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--black);
            margin-bottom: .3rem;
            display: block;
        }
        input, textarea, select {
            border-radius: 0.625rem;
            border: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45));
            background: var(--Blanco, #FFF);
            font-family: Manrope, sans-serif;
            font-size: 0.75rem;
            margin-bottom: .7rem;

        }
        select {
            width: auto;
            height: auto !important;
            padding: .44rem;
            outline: none;
            ::after {
                right: 1rem !important;
            }
        }
        textarea {
            margin-bottom: 1rem;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 2rem;
            margin-bottom: 2rem;
            font-size: 0.9rem;
            color: var(--gray);
            background: rgba(250, 250, 250, 0.84);
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.20), 4px 5px 14px 0px rgba(0, 0, 0, 0.20);
            border-radius: 15px;
            overflow: hidden;
        }
        caption {
            font-size: 2rem;
            color: var(--blue);
            margin-bottom: 1rem;
            text-align: center;
        }

        thead {
            background: var(--blue);
            color: var(--white);
        }
        
        th {
            padding: 0.8rem;
            text-align: left;
            font-weight: bold !important;
            background-color: #f1f1f1;
        }
        
        td {
            padding: 0.8rem;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }
        
        tr:nth-child(even) {
            background-color: var(--light-gray);
        }
        
        tr:hover {
            background-color: #f5f5f5;
        }          
    }
    .remove {
        background: transparent;
        border: 0;
        padding: 0;
        width: 1.5rem;
        margin-bottom: 12px;
        cursor: pointer;
    }
    &-variable-button {
        border-radius: 2px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 14px;
        padding: 3px 11px;
        background: #D4D4D4;
        color: #5A5A5A;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    &-filename-button {
        border-radius: 2px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 14px;
        padding: 3px 11px;
        background: rgba(144, 2, 255, 0.60);
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }    
    &-submit-button {
        border-radius: 37px;
        padding: 8px 20px;
        background: var(--black);
        color:var(--white);
        margin-top: 12px;
        margin-bottom: 2rem;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        &[disabled]{
            color: var(--black);
            svg {
                path {
                    fill: var(--black);
                }
            }
        }
        &:hover, &:active, &:visited {
            background: var(--white);
            color: var(--black);
            svg path {
                fill: var(--black) !important;
              }
        }
    }
    &-buttons {
        display: flex;
        justify-content: flex-end;
    }    
    &-save-button {
        border-radius: 37px;
        padding: 8px 20px;
        background: var(--white);
        color:var(--black);
        margin-top: 12px;
        margin-bottom: 2rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        margin-right: .85rem;
        svg {
            margin-right: 8px;
        }
        &:hover, &:active, &:visited {
            background: var(--white);
            color: var(--black);
            svg path {
                fill: var(--black) !important;
              }
        }
    }
    .add-variable {
        background: var(--gray-light);
        border: 1px solid var(--black);
        color: var(--black);
        padding: 5px 17px;
        border-radius: 37px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        &:hover, &:active, &:visited {
            background: var(--black);
            color: var(--gray-light);
            svg path {
                fill: var(--gray-light) !important;
              }
        }
    }
    .add-file span {
        display: flex;
        align-items: center;
    }

    .file-info-container {
        display: flex;
        align-items: center;
    }
    
    .file-info {
        border: rgba(144, 2, 255, 0.60);
        background: rgba(144, 2, 255, 0.60);
        color: var(--gray-light);        
        padding: 5px 17px;
        margin-left: 1rem;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;        
    }
    
    .delete-file-button {
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 5px 10px;
        margin-left: 1px;
        background: rgba(144, 2, 255, 0.60);
        border: rgba(144, 2, 255, 0.60);
        cursor: pointer;
    }
    .add-file-control {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .delete-button {
        display: flex;
        justify-content: flex-end;
    }
    .toggle-button {
        max-width: 60px !important;
    }
    &-dropdown {
        margin-top: 1rem;
        select{
            cursor: pointer;
            padding: 0 1rem;
        }
    }
    &-dropdown {
        &-button {
            border-radius: 1.25rem;
            background: var(--Negro, #000);
            display: flex;
            gap: .62rem;
            align-items: center;
            color: var(--Blanco, #FFF);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            border: 0;
            outline: 0;
            padding: .62rem;
            cursor: pointer;
            svg {
                path {
                    stroke: white;
                }
            }
        }
    }
    .dropdown-content {
        padding: 0;
        border-radius: 0rem 0rem 0.3125rem 0.3125rem;
        background: var(--Blanco, #FFF);
    }
    .dropdown-menu {
        min-width: auto;
    }
    .dropdown-menu {
        .field.is-grouped{
            margin: 0;
        }
        .add-file-control {
            margin: 0;
        }
        button {
            background-color: transparent !important;
            border: 0;
            padding: 0;
            color: var(--Negro, #000) !important;
            font-family: Manrope, sans-serif;
            font-size: 0.75rem;
            font-weight: 400;
            padding: .3rem;
            border-bottom: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45)) !important;
            border-radius: 0;
            width: 100% !important;
            cursor: pointer;
            display: flex;
            gap: .62rem;
            justify-content: flex-start;
        }
    }
    .variables-container {
        border-radius: 0.625rem;
        border: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45));
        background: var(--Blanco, #FFF);
        overflow: hidden;
        margin-top: 1.7rem;
        .new-app-form-field-control {
            margin: 0;
            padding: 0 1rem;
        }
        .remove {
            background-color: black;
            padding: .13rem;
            border-radius: 0rem 0rem 0rem 0.5rem;
            svg {
                max-width: 21px;
                path {
                    stroke: white;
                }
            }
        }
    }
}
.model-description {
    position: relative;
}

.info-icon {
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.disclaimer {
    font-size: 14px !important;
    border: 1px solid #ccc;
    margin-top: 2rem;
    padding: 10px !important;
    background-color: #f0f0f0 !important;
}

.description-tooltip {
    visibility: hidden;
    width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 10%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
      position: absolute;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }

  .model-description:hover .description-tooltip {
    visibility: visible;
    opacity: 1;
  }



.icon {
    max-width: 1rem;
    position: absolute;
    top: -10px;
    right: 8px;
}


@media (min-width: 768px) {
    .new-app-columns {
        flex-direction: row;
    }
    .new-app-form-section {
        label {
            width: 40%;
        }
    }
}

@media (max-width: 768px)  {
    .new-app {
        padding: 1rem 1.63rem;
    }
}


@media (min-width: 768px)  {
    .new-app {
        width: calc(100% - 15.6rem);
        padding: 0;
        &-content {
            margin: 0;
            padding: 0 2rem 2rem 2rem;
        }
        &-container {
            padding: 1rem;
        }
    }
}