.navbar {
    background: linear-gradient(to bottom, #f0f0f0, #e5e5e5) !important;
    border: 0;
    z-index: 99;
    position: fixed;
    width: 100%;
    padding-bottom: 1.12rem;
    &.clearbg {
        background: transparent !important;
        position: absolute;
    }
    .center-items {
        justify-content: center;
        display: flex;
        padding-top: 2.6rem;
    }
    .navbar-switch-buttons {
        display: flex;
        gap: .9rem;
        justify-content: center;
        .navbar-item {
            border-radius: 0.88906rem;
            background: #BFBFBF !important;
            backdrop-filter: blur(7.112375259399414px);
            color: #FFF;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.375rem;
            border: 0;
            min-height: 0;
            padding: .5rem 3rem !important;
            &.active {
                border-radius: 0.88906rem;
                background: #181818 !important;
                backdrop-filter: blur(7.112375259399414px);
            }
        }
    }
}

.menu-internal {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100%;
    &.open {
        display: block;
        height: 100%;
        position: fixed;
        width: 85%;
        padding: 0;
        z-index: 99999999999;
        .mobile-menu-internal {
            background-color: white;
            height: 100%;
            overflow-y: scroll;
            z-index: 1;
            position: relative;
            display: block;
        }
        .open-background {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: black;
            opacity: 0.1;
        }
        .mobile-menu-internal-left {
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: .63rem .63rem .63rem 0;
            min-height: 5rem;
            .mobile-menu-internal-left-home {
                display: none;
            }
        }
        .logo-mobile {
            display: block;
            padding: 0 !important;
            background-color: white !important;
            line-height: 0;
            max-height: 47px;
            min-height: 0;
        }
        .mobile-menu-internal-right-user {
            border-top: 1px solid var(--Negro, #000);
            border-bottom: 1px solid var(--Negro, #000);
            border-style: dashed;
            border-radius: 0;
            box-shadow: none;
            padding: 1.25rem 1.56rem;
        }
    }
    .mobile-menu-internal {
        height: 100%;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .open-background {
        display: none;
    }
    &-content {
        display: none;
    }
    &-content.open {
        display: block;
    }

    .logo-desktop {
        display: none;
    }
    .logo-mobile {
        display: none;
    }
    .menu-toggle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 24px;
        height: 18px;
        cursor: pointer;
        z-index: 100;

        .bar {
            width: 24px;
            height: 3px;
            background-color: var(--text-color-over-dark-primary);
            transition: transform 0.3s, opacity 0.3s;
        }

        &.open {
            .bar:nth-child(1) {
                transform: rotate(45deg) translate(5px, 5px);
            }
            .bar:nth-child(2) {
                opacity: 0;
            }
            .bar:nth-child(3) {
                transform: rotate(-45deg) translate(5px, -5px);
            }
        }
    }
    .logo:hover {
        background-color: transparent;
    }
    &-sup {
        &-user {
            background: rgba(238, 238, 238, 0.7);
            border-radius: 1.5rem;
            margin-bottom: 38px + 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: var(--text-color-over-dark-primary);
            &-img {
                margin-right: 1rem;
            }
            a:hover, a:active, a:focus {
                background: rgba(238, 238, 238, 0.7) !important;
                border-radius: 1.5rem !important;
                color: var(--text-color-over-dark-primary);
            }
            .sb-avatar {
                margin-right: 0.2rem;
            }
            &-team {
                margin-left: .5rem;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
            }
            &-credits {
                margin-left: .2rem;
                color: rgba(51, 51, 51, 0.90);
                font-size: 0.625rem;
                font-style: normal;
                font-weight: 400;
                span {
                    font-weight: bold;
                }
            }
        }
    }
    .navbar-item {
        color: var(--Negro, #000) !important;
        font-family: Manrope, sans-serif;
        font-size: 1rem;
        padding: 1.22rem 1.56rem;
        gap: 1rem;
        display: flex;
        &:hover {
            background-color: #fafafa;
        }
        path {
            stroke: black;
        }
    }
    .team-settings {
        path {
            stroke: none;
        }
    }
    &-logout {
        font-weight: 500;
        font-size: 1rem;
        text-align: left;
        color: var(--text-color-over-dark-primary-90);
        justify-content: flex-start;
        width: 100%;
        border: 0;
        border-radius: 0;
        position: absolute;
        bottom: 12px;
        padding: 1.22rem 1.56rem;
        height: auto;
        border-top: 1px solid var(--Negro, #000);
        gap: 1rem;
        svg {
            margin-right: 8px;
        }
    }
    .team-section {
        display: flex;
        align-items: center;
        &-setting {
            z-index: 99;
            padding: 0 !important;
            line-height: 0 !important;
            border: none !important;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
            svg {
                margin: 0;
            }
        }
        &-general {
            display: flex;
            align-items: center;
        }
    }
    .flipper-menu {
        background: none;
        border: 0;
        box-shadow: none;
        color: #333;
        font-size: 0.875rem;
        font-weight: 600;
        padding-left: 24px;
        padding-right: 0;
    }
}
.mobile-menu-internal-left {
    display: flex;
    gap: 1.68rem;
    align-items: center;
}
.mobile-menu-internal-right-user {
    padding: .62rem;
    border-radius: 2rem;
    background: var(--Blanco, #FFF);
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.05);
    border: 0;
    display: flex;
    gap: .62rem;
    align-items: center;
    &-description {
        display: flex;
        align-items: center;
        gap: .62rem;
        color: var(--Negro, #000);
        font-family: Manrope, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        span {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 300;
        }
    }
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.25rem;
    padding-top: 1.37rem;
    padding-right: 1.7rem;
    padding-left: 2.75rem;
    width: 100%;
    &-left {
        display: flex;
        align-items: center;
        gap: .5rem;
        svg {
            display: block;
            background: #D4D4D4;
            border-radius: .31rem;
            padding: .3rem;
            width: 1.8rem;
            height: 1.8rem;
            path {
                stroke: black;
            }
        }
        .back-icon {
            border: 0;
            outline: none;
            background: transparent;
            padding: 0;
            box-shadow: none;
            cursor: pointer;
            svg {

                transform: rotate(270deg);
            }
        }
    }
    &-right {
        display: flex;
    }
    &-tab {
        display: flex;
        align-items: center;
        gap: 1.8rem;
        transition: all 0.3s ease-in-out;
        &-item {
            padding: .62rem;
            border-radius: 1.25rem;
            background: var(--Negro, #000);
            color: var(--Blanco, #FFF) !important;
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            display: flex;
            gap: .62rem;
            path {
                stroke: white;
                width: 1.3rem;
            }
            &.active {
                border-radius: 1.25rem;
                background: var(--Blanco, #FFF);
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
                color: var(--Negro, #000) !important;
                path {
                    stroke: black;
                    width: 1.3rem;
                }
            }
        }
    }
    #home {
        path {
            fill: none;
        }
    }

    .dropdown {
        .navbar-item {
            border-radius: 1.25rem;
            background: var(--Blanco, #FFF);
            box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.05);
            border: 0;
        }
    }
    .settings-user-button {
        gap: .5rem;
    }
    .dropdown-menu {
        right: 0;
        top: 46px;
        left: auto;
    }
    .dropdown-item {
        border: 0;
    }
}
.logo-mobile {
    border: 0 !important;
    background: transparent !important;

}

.community.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        fill: #9C77FF;
    }

}

.recent.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #B2DF53;
    }
}

.menuTeam.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        fill: #83BCFF;
    }
}

.menuNews.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        fill: #FF70FF;
    }
}

.menuGenerator.active {
    color : #FF70FF;
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #FF70FF;
    }
}

.menuTemplate.active {
    color : #FF70FF;
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #FF70FF;
    }
}

.menuWorkflow.active {
    color : #FF70FF;
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #FF70FF;
    }
}


@media (min-width: 768px) {
    .navbar {
        position: relative;
        z-index: 0;
        &.clearbg {
            position: relative;
        }
    }
    .mobile-menu-internal-left {
        display: none;
    }
    .menu-internal {
        height: 100vh;
        min-width: 15.6rem;
        width: auto;
        background-color: var(--white);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        position: fixed;
        padding: 0;
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            &-flipper {
                display: flex;
                align-items: center;
                gap: .44rem;
                border: 0;
                background: transparent;
                padding: .63rem 1.62rem;
                outline: 0;
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.875rem;
                font-weight: 400;
                cursor: pointer;
            }
            .navbar-item  {
                padding: .63rem 1.62rem;
                gap: .44rem;
                font-size: 0.875rem;
                font-weight: 400;
                max-width: 15.7rem;
                .menuicon {
                    width: 1.3rem;
                    height: 1.3rem;
                }
            }
            &-fristlevel {
                border-top: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45));
            }
        }
        &-logout {
            position: relative;
            border-top: 0;
            font-size: 0.875rem;
            font-weight: 400;
            gap: .44rem;
            padding: .63rem 1.62rem;
            svg {
                margin: 0;
            }
        }
        .submenu {
            &-button {
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.75rem;
                font-weight: 700;
                text-transform: uppercase;
                border: 0;
                background: transparent;
                outline: 0;
                display: flex;
                align-items: center;
                padding: 1.2rem 1.62rem;
                gap: .44rem;
                cursor: pointer;
                svg {
                    transform: rotate(270deg);
                }
            }
            &.open {
                .submenu-button {
                    svg {
                        transform: rotate(0deg);
                    }
                }
            }
            &-content {
                padding-left: 1.5rem;
            }

        }
        .language-switcher {
            padding: .63rem 1.62rem;
            margin-bottom: .65rem;
            svg {
                margin: 0;
            }
            .language-button {
                font-size: 0.875rem;
            }
        }
        .logo-desktop {
            background: transparent !important;
        }
    }
    .menu-toggle {
        display: none !important;
    }
    .logo-desktop {
        display: block !important;
    }
    .logo-mobile {
        display: none !important;
    }
    .mobile-menu-internal {
        display: block;
    }
    .special-zindex {
        z-index: 99;
    }
}

.transparentbg {
    background: transparent !important;
}