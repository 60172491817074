.flipnews {
    padding: 12rem 1rem 9rem 1rem;
    background: linear-gradient(180deg, rgba(255, 112, 255, 1) 0%, rgba(255, 112, 255, 0.00) 100%);
    &::after {
        content: "";
        position: fixed;
        bottom: 100%;
        right: 8%;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #FF70FF;
        top: 9.891rem;
        z-index: 9999;
      }
}

@media (min-width: 768px) {
  .flipnews {
      width: calc(100% - 15.6rem);
      padding: 0;
      &-container {
          padding: 1rem;
      }
      &.nomenu-padding {
          .flipnews-container {
              padding-top: 25rem;
              padding-bottom: 4rem;
          }
      }
      &::after {
        display: none;
      }
  }
}