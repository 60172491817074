.terms {
    width: 100%;
    padding: 2rem 4rem;
    h2 {
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: 2rem;
        margin-bottom: .5rem;
    }
    p {
        margin-bottom: 1rem;
    }
}