.toggle-button {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 1.4rem;
    border-radius: 34px;
  }
  
  .toggle-button input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: .8rem;
    width: .8rem;
    left: 4px;
    bottom: 4px;
    background-color: black;
    transition: .4s;
    border-radius: 50%;
  }
  
  // input:checked + .slider {
  //   background-color: #000;
  // }
  
  input:checked + .slider:before {
    transform: translateX(41px);
  }
  
  /* Estilos adicionales para personalizar la apariencia */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  