.my-app {
    width: 100%;
    padding: 7rem 1rem 7rem 1rem;
    background-image: url("../../assets/newDesign/backgrounds/createbg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    &-container {
        &-title {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 1.625rem;
            font-weight: 600;
            margin-bottom: 2.5rem;
        }
        &-search-title {
            margin-bottom: .94rem;
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 1rem;
            font-weight: 400;
        }
        .searcher {
            margin-bottom: 2rem;
        }
        &-carousel {
            h2 {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: .25rem;
            }
            margin-bottom: 1.5rem;
        }
        &-tabs {
            h2 {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: .25rem;
            }
            &-container {
                gap: .63rem;
                display: flex;
                flex-wrap: wrap;
                .button {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem .63rem;
                    border-radius: 0.625rem;
                    background: var(--blanco-60, rgba(255, 255, 255, 0.60));
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15) !important;
                    outline: 0 !important;
                    border: 0 !important;
                    span {
                        display: flex;
                        gap: 1.19rem;
                        svg {
                            background: black;
                            padding: .25rem;
                            border-radius: 50%;
                            stroke: white;
                        }
                    }
                    &.active {
                        background: var(--Gris-claro, #E8E6EA);
                        color: var(--Negro, #000);
                        span {
                            svg {
                                background: var(--Blanco, #FFF);
                                path {
                                    stroke: black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-header {
        display: flex;
        width: 100%;
    }
    &-empty {
        font-size: 2.5rem;
        margin-top: 3rem;
    }
    &-sections {
        &-title {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 1.625rem;
            font-weight: 600;
            margin-bottom: 2.5rem;
            button {
                border: 0;
                background: transparent;
                cursor: pointer;
                outline: none;
                padding: 0;
                svg {
                    transform: rotate(180deg);
                }
            }
            h2 {
                color: #5A5A5A;
                font-family: Manrope, sans-serif;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 2rem;
                margin-right: 2rem;
                
            }
        }
        h2 {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        .searcher {
            margin-bottom: 1rem;
        }
        &-container {
            &-columns {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                &-column {
                    width: 100%;
                }
            }
        }
    }
    .table {
        width: 100%;
        background-color: transparent;
    }
    .list-view {
        &-actions {
            display: flex;
            align-items: center;
            &-button {
                border: 0;
                background: transparent;
                cursor: pointer;
            }
        }
        th {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
        }
        td {
            color: #000;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            a {
                color: #0267FF;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
            }
        }
    }
}

.team-tab {
    &.active {
        background: var(--Celeste, #83BCFF) !important;
        color: var(--Blanco, #FFF) !important;
        svg {
            path {
                stroke: var(--Blanco, #FFF) !important;
            }
        }
        span {
            svg {
                background: var(--Blanco, #FFF) !important;
                path {
                    stroke: #83BCFF !important;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .my-app  {
        width: calc(100% - 15.6rem);
        padding: 0;
        &-container {
            padding: 0 2rem 2rem 2rem;
            &-title {
                color: #000;
                font-family: "PP Mori", sans-serif;
                font-size: 2.25rem;
                font-weight: 400;
            }
            &-search-title {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 1rem;
                font-weight: 100;
                margin-bottom: .75rem;
            }
        }
        &-sections {
            &-title {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 2.25rem;
                font-weight: 600;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                gap: 1.75rem;
            }
            .searcher {
                margin-bottom: 2.44rem;
            }
            &-container {
                &-columns {
                    gap: 1.87rem;
                    &-column {
                        width: auto;
                    }
                }
            }
        }
    }
    .my-app-container-tabs-container {
        gap: 3.25rem;
        margin-bottom: 3rem;
        .button {
            max-width: 13rem;
            cursor: pointer;
        }
    }
  }