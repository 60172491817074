.banner-loop {
    overflow: hidden;
    background-color: #000;
    white-space: nowrap;
    box-sizing: border-box;
    
    .banner-loop-content {
      display: inline-block;
      will-change: transform;
      animation: loop-animation 240s linear infinite;
    }
    
    @keyframes loop-animation {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    span {
        color: #FFF;
        font-size: .9rem;
        font-style: italic;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
    }
  }
  