.appCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 13rem;
    border-radius: 1.25rem;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    background-image: url("../../assets/newDesign/backgrounds/cards.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    &:hover {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1rem .6rem 1rem;
        &-badges {
            display: flex;
            gap: .12rem;
            svg {
                display: block;
            }
        }
        &-actions {
            display: flex;
            align-items: center;
            gap: .25rem;
            z-index: 10;
            .info-tooltip {
                max-height: 26px;
                &::after{
                    width: 150px;
                    left: 50;
                    font-size: 10px;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    max-height: 3.6rem;
                    text-align: left;
                    background: white;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
                }
                &::before{
                    border-top-color: white;
                }
            }
            .heartButton {
                top: 0;
                right: 0;
                position: relative;
            }
            .dropdown {
                border: 0;
                background-color: transparent;
                 height: fit-content;
                width: fit-content;
                max-height: 26px;
                .appCard-options-button {
                    border: 0;
                    background: transparent;
                    padding: 0;
                    z-index: 8;
                    cursor: pointer;
                }
                .appCard-options-option {
                    border: 0;
                    background: transparent !important;
                    color: var(--Negro, #000);
                    font-family: Manrope, sans-serif;
                    font-size: 0.875rem;
                    font-weight: 300;
                }
            }
        }
    }
    .card-header, .card-footer {
        position: relative;
        border: 0;
        box-shadow: none;
        font-size: 0.8rem;
    }
    .card-content {
        padding: 1rem 1rem .6rem 1rem;
    }
    .card-footer {
        padding: 0 1rem 1.2rem 1rem;
        display: flex;
        gap: .38rem;
        align-items: center;
        &-username {
            margin-left: .3rem;
        }
        p {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.625rem;
            font-weight: 300;
        }
        img {
            width: 2rem;
            height: 2rem;
            border-radius: 2rem;
            margin: 0;
        }
    }
    .content-title {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 2.5rem;
        min-height: 2.5rem;
        margin-bottom: 0;
        color: var(--Negro, #000);
        font-family: PP Mori, sans-serif;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .card-header-badges {
        margin-bottom: .75rem;
    }
}


.appCard-options-button {
    border: 0;
    background: transparent;
    padding: 0;
    z-index: 10;
    cursor: pointer;
    height: 26px;
}