.login {
    padding: 12rem 3rem 2rem 3rem;
    &-content {
        border-radius: 1.33356rem;
        border: 1px solid #FFF;
        background: rgba(255, 255, 255, 0.60);
        padding: 1.8rem 1rem;
        margin-bottom: 1rem;
        &-title {
            color: #000;
            font-size: 1.125rem;
            font-weight: 400;
            margin-bottom: 4.6rem;
        }
        &-form {
            &-field {
                label {
                    color: #181818;
                    font-size: 0.8125rem;
                    font-weight: 700;
                    line-height: 1rem;
                }
                input {
                    border-bottom: 1px solid black;
                    border-color: black !important;
                    box-shadow: none !important;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-radius: 0;
                    padding: 0;
                    background: transparent;
                    outline: none !important;
                    &::placeholder {
                        color: rgba(51, 51, 51, 0.60);
                        font-size: 0.8125rem;
                        font-weight: 400;
                        line-height: 1rem;
                    }
                }
                .eye-icon {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    width: 20px;
                    height: auto;
                    fill: #ccc;
                }
                &-description {
                    overflow: hidden;
                    color: rgba(0, 0, 0, 0.60);
                    font-size: 0.8125rem;
                    font-weight: 400;
                    line-height: 1rem;
                    margin-top: .44rem;
                }
                .PhoneInput {
                    input {
                        line-height: 1.5rem;
                        height: 2.5em;
                    }
                }
            }
            .checkbox {
                color: #181818;
                font-size: 0.8125rem;
                font-weight: 700;
                line-height: 1rem;
                margin-bottom: 0.75rem;
                input {
                    margin-right: .5rem;
                }
            }
        }
        &-new-password {
            text-align: right;
            margin-bottom: 2.1rem;
            a {
                border-bottom: 1px dashed #FF42FF;color: #FF42FF;
                font-size: 0.8125rem;
                font-weight: 700;
                line-height: 1rem;
            }
        }
        &-captcha {
            margin-bottom: 1.1rem;
        }
        &-submit {
            width: 100%;
            margin-bottom: 1.25rem;
            border-radius: 0.88906rem;
            background-color: #000 !important;
            backdrop-filter: blur(7.112375259399414px);
            color: #FFF;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.375rem;
        }
        &-google-submit {
            border-radius: 0.88875rem;
            background-color: #FFF !important;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            display: flex;
            width: 100%;
            gap: .5rem;
            color: #000 !important;
            text-align: center;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1rem;
            border: 0;
            .google-logo {
                width: 1.25rem;
            }
        }
    }
    &-footer {
        color: #181818;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: center;
        a {
            color: #FF42FF;
            font-size: 0.8125rem;
            font-weight: 700;
            line-height: 1rem;
        }
    }
    .banner-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99999;
    }
    .banner-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99999;
    }
    &-forgot-password {
        &-subtitle {
            color: #000;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1rem;
            margin-bottom: 2rem;
        }
    }
    &-info-message {
        color: #000;
        text-align: center;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1rem;
        margin-bottom: 1.44rem;
        a {
            margin-left: .5rem;
            color: #000;
            font-size: 0.8125rem;
        }
    }
    &-resend-link {
        text-align: right;
        font-size: 0.8125rem;
        margin: 0.5rem;
        a {
            color: #FF42FF;
            cursor: pointer;
            font-weight: bold;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.forgot-password {
    &-title {
        margin-bottom: 1rem;
    }
}

@media (min-width: 768px)  {
    .login-content-title {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5rem;
        margin-bottom: 1.8rem;
    }
    .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 3rem;
    }
    .login-content {
        max-width: 50rem;
        width: 100%;
        padding: 2.8rem 8.9rem;
    }
    .login-content-submit, .login-content-google-submit {
        font-size: 1rem;
    }
}