.appCardMini {
    border-radius: 1.25rem;
    border: 1px solid var(--Gris, #D4D4D4);
    background: var(--blanco-60, rgba(255, 255, 255, 0.60));
    backdrop-filter: blur(10px);
    overflow-x: hidden;
    cursor: pointer;
    .card-header-actions {
        display: flex;
        gap: .77rem;
        align-items: center;
        padding: .5rem;
        
        .info-tooltip {
            max-height: 26px;
            &::after{
                width: 150px;
                left: -50px;
                font-size: 10px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                max-height: 3.6rem;
                text-align: left;
                background: white;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
            }
            &::before{
                border-top-color: white;
            }
        }
        .dropdown {
            border: 0;
            background-color: transparent;
            z-index: 8;    
            height: fit-content;
            width: fit-content;
            max-height: 26px;
            &.is-active {
                .dropdown-menu {
                    display: block;
                    left: auto;
                    right: 0;
                }
            }
            .appCard-options-button {
                border: 0;
                background: transparent;
                padding: 0;
                z-index: 8;
                cursor: pointer;
            }
            .appCard-options-option {
                border: 0;
                background: transparent !important;
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.75rem;
                width: 100%;
                font-weight: 300;
                text-align: left;
                border-bottom: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45));
                border-radius: 0;
                justify-content: flex-start;
                padding: .5rem 1rem;
                cursor: pointer;
                &:hover {
                    background: var(--Gris-claro, #E8E6EA) !important;
                }
            }
        }
    }
    .card-content {
        padding: .5rem 1.19rem;
        .content-title {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            max-height: 2.4rem;
            min-height: 2.4rem;
            color: var(--Negro, #000);
            font-family: PP Mori, sans-serif;
            font-size: 1rem;
            font-weight: 600;
            width: 14rem;
        }
    }
    .card-footer {
        display: flex;
        gap: .5rem;
        border: 0;
        padding: 0 1.19rem .63rem 1.19rem;
        img {
            width: 1rem;
            height: 1rem;
            border-radius: 1rem;
            margin: 0;
        }
        .sb-avatar {
            width: 1rem !important;
            height: 1rem !important;
            .sb-avatar__text {
                width: 1rem !important;
                height: 1rem !important;
            }
        }
        p {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.625rem;
            font-weight: 300;
        }
    }
}

.card-header {
    justify-content: space-between;
    box-shadow: none;
    &-badge {
        padding: .63rem;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        &::after {
            margin-left: -12px !important;
        }
    }
    &-badges {
        display: flex;
        p {
            color: var(--Negro, #000);
            font-family: Roboto, sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 300;
            text-transform: uppercase;
            padding: .62rem;
        }
        p:first-child {
            margin-left: 2.2rem;
        }
        svg {
            display: block;
        }
        & > div:first-child {
            border-radius: 0rem 0rem 0.5rem 0rem;
        }
    }
}
.team-badge {
    background: var(--Celeste, #83BCFF);
}
.top-badge {
    background: var(--Violeta, #9C77FF);
        svg {
            path {
            stroke: white;
            }
        }
}
.private-badge {
    background: var(--Negro, #000);
    svg {
        path {
        stroke: white;
        }
    }
}
.template-badge {
    background: #FF70FF;
    svg {
        path {
        stroke: white;
        }
    }
}
.live-badge {
    background: var(--Negro, #000);
    svg {
        path {
        stroke: var(--Celeste, #83BCFF);
        }
    }
}

@media (min-width: 768px)  {
    .appCardMini .card-content {
        padding: 0.5rem 7rem .5rem 3rem;
    }
    .appCardMini .card-footer {
        padding: 0 1.19rem 0.63rem 3rem;
    }
    .appCardMini .card-content .content-title {
        max-width: 11rem;
    }
}