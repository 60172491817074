.settings {
    padding: 12rem 1rem 8rem 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    &-form {
        max-width: 655px;
        align-self: center;
        width: 100%;
        &-body {
            h3 {
                background: #EEEEEE;
                border-radius: 15px;
                padding: 8px 16px;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                display: block;
                margin-bottom: 18px;
            }
        }
        &-field {
            position: relative;
            display: flex;
            gap: 20px;
            margin-bottom: 16px;
            padding: 0 20px;
            flex-direction: column;
            label {
                flex-basis: 50%;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
            }
            input {
                background: #FFFFFF;
                border: 1px solid #C3C3C3;
                border-radius: 15px;
                flex-basis: 50%;
                min-width: 263px;
            }
            .eye-icon {
                position: absolute;
                right: 30px;
                top: 75%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 20px;
                height: auto;
                fill: #ccc;
            }          
        }
        &-footer {
            border-top: 1px solid rgba(195, 195, 195, 0.8);
            padding: 22px 78px 22px 78px;
            text-align: center;
            &-button {
                background: rgba(0, 0, 0, 0.85);
                border-radius: 37px;
                padding: 8px 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
            }
        }
    }

    .PhoneInput {
        gap: .5rem;
        padding: 0 20px;
        input {
            color: #363636;
            background: #FFFFFF;
            border: 1px solid #C3C3C3;
            border-radius: 15px;
            flex-basis: 50%;
            min-width: 263px;
            outline: none !important;
            padding: 0.5rem 0.75rem;
        }
    }  
}
.errorMessage {
    font-weight: bold;
    color: red;
    text-align: center;
  }

  @media (min-width: 768px) {
    .settings-form {
        width: auto;
    }
    .settings-form-field {
        flex-direction: row;
    }
    .settings-form-body {
        padding: 86px 78px 22px 78px;
    }
    .settings-form-field .eye-icon {
        top: 50%;
    }
  }


@media (min-width: 768px) {
    .settings {
        width: calc(100% - 15.6rem);
        padding: 0;
        &-container {
            padding: 0 2rem 2rem 2rem;
        }
    }
  }
  