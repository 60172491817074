.templates {
    width: 100%;
    padding: 2rem 4rem;
    &-header {
        display: flex;
        width: 100%;
    }
    &-empty {
        font-size: 2.5rem;
        margin-top: 3rem;
    }
    &-header {
        background: white;
        position: fixed;
        width: 100%;
        top: 10.8rem;
        z-index: 10;
        background: white;
        margin-left: -1rem;
        padding: 1rem;
        .searcher {
            background: var(--Gris, #D4D4D4);
        }
    }
}

@media (max-width: 768px) {
    .templates {
        padding: 12rem 1rem 9rem 1rem;
    }
}
@media (min-width: 768px)  {
    .templates {
        width: calc(100% - 15.6rem);
        background-image: url("../../assets/newDesign/backgrounds/templatesbg.png");
        min-height: 100vh;
        padding: 0;
        &-header {
            position: relative;
            margin: 0;
            top: 0;
            background: transparent;
            padding: 0;
            z-index: 1;
            display: block;
            h1 {
                color: #000;
                text-align: center;
                font-family: "PP Mori", sans-serif;
                font-size: 3.875rem;
                font-weight: 100;
                margin-bottom: 1rem;
            }
            p {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 1.125rem;
                font-weight: 400;
                margin-bottom: 2.25rem;
                text-align: center;
            }
        }
        &-content {
            margin: 0;
            padding: 0 2rem 2rem 2rem;
        }
        &-container {
            padding: 1rem;
        }
    }
}