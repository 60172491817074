.join-team {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
  
    .modal-content {
      width: 500px;
      padding: 2rem;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  
      .join-team-modal-title {
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
        text-align: center;
      }
  
      h3 {
        margin-bottom: 2rem;
        text-align: center;
        font-size: 1.5rem;
      }
  
      .confirmation-buttons {
        display: flex;
        justify-content: space-between;
  
        button {
          width: 45%; // This ensures the buttons don't touch each other
          padding: 10px;
          font-size: 1rem;
          border-radius: 4px;
          cursor: pointer;
          border: none;
  
          &.is-danger {
            background: #f56565; // Red
            color: white;
          }
  
          &.is-light {
            background: #f0f0f0; // Light gray
            color: #333;
          }
        }
      }
    }
  
    .modal-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0,0,0,0.3);
    }
  }
  