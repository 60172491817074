.workflows-list {
    width: 100%;
    padding: 0rem 4rem 0 4rem;
    background-image: url("../../../assets/newDesign/backgrounds/createbg.png");
    background-position: center;
    background-repeat: repeat-y !important;
    &-content {
        min-height: 100vh;
        padding-top: 2rem;
        &-header {
            padding: 2.6rem 2.1rem 3.1rem 2.1rem;
            margin-bottom: 1.25rem;
            border-radius: 1.875rem;
            background: #EEE;
            h1 {
                color: #000;
                font-family: Manrope;
                font-size: 2rem;
                font-weight: 500;
                margin-bottom: .7rem;
            }
            p {
                color: #5A5A5A;
                font-family: Manrope;
                font-size: 0.875rem;
                font-weight: 400;
            }
        }
        &-subtitle {
            padding: 2rem 0 1rem 0;
            display: flex;
            gap: .6rem;
            margin-bottom: 1.9rem;
            flex-wrap: wrap;
            .searcher {
                max-width: 19rem;
            }
        }
    }
    .list-view {
        width: 100%;
        background: transparent;
        flex-wrap: wrap;
        thead th {
            padding-bottom: 0.2rem;
        }
        td {
            color: #000;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            a {
                color: #0267FF;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
            }
        }
        tbody {
            tr:first-child {
                border-top: 0.5px solid #000;
            }
        }      
        &-actions {
            display: flex;
            align-items: center;
            &-button {
                border: 0;
                background: transparent;
                cursor: pointer;
            }
        }        
    }
}
.create-workflow {
    border-radius: 1.25rem;
    background: #E1E0E0;
    padding: 1.2rem 1rem;
    display: flex;
    gap: .5rem;
    color: #000;
    font-family: Manrope;
    font-size: 0.875rem;
    font-weight: 500;
    svg {
        fill: #000;
        width: .7rem;
    }
}

@media (max-width: 768px) {
    .workflows-list {
      padding: 1rem 1.7rem;
      .list-view {
        width: 100%;
      }
    }
}