.explore {
    padding: 16rem 1rem 9rem 1rem;
    position: relative;
    &-sort {
        display: flex;
        align-items: center;
        gap: 2rem;
    }
    &-columns {
        flex-wrap: wrap;
        align-items: stretch;
    }
    &-subtitle {
        font-size: 1.125rem;
        margin-bottom: 1rem;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        &-button {
            border-radius: 1.25rem;
            background: var(--Negro, #000);
            padding: .62rem;
            color: var(--Blanco, #FFF);
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            border-radius: 1.5rem;
            border: 0;
        }
    }
    &-teams {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        margin: 0;
        span {
            font-size: 0.875rem;
            font-weight: 600;
        }
        label {
            margin: 0 .6rem;
        }
    }
    &-template {
        padding-bottom: 4rem;
        border-bottom: 1px solid rgba(195, 195, 195, 0.80);
        h2 {
            color: #FF42FF;
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }
    }
    &-team {
        margin-bottom: 2rem;
        h2 {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 1rem;
            text-transform: capitalize;
        }
        .appCard {
            border: 1px solid rgba(144, 2, 255, 0.40);
        }
    }
    &-category-buttons {
        margin-top: 1rem;
        margin-bottom: 2rem;
        gap: .88rem;
        display: flex;
        flex-wrap: wrap;
        color: var(--Negro, #000);
        font-family: PP Mori, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 100;
        label {
            font-weight: 600;
        }
        &-button {
            cursor: pointer;
        }
        &-down {
            transform: rotate(180deg);
        }
    }
    &-primary-buttons {
        width: 100%;
        margin-bottom: 22px;
        .explore-primary-container-buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 28px;
        }
        h1 {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        a {
            padding: 25px;
            display: flex;
            flex-direction: column;
            flex: 1;
            background: linear-gradient(139deg, #FFF 40.5%, #d6d6d6 100%);
            text-align: center;
            align-items: center;
            color: #000 !important;
        }
        .new-app-border {
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.40);
        }
        .generator-border {
            border-radius: 15px;
            border: 1px solid rgba(144, 2, 255, 0.40);
        }
        .template-border {
            border-radius: 15px;
            border: 1px solid rgba(255, 66, 255, 0.40);
        }
    }
    &-most-used {
        margin-bottom: 2.5rem;
        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.63rem;
        }
    }
    &-templates {
        color: #000;
        font-family: "PP Mori", sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
    }
    &-header {
        background: white;
        position: fixed;
        width: 100%;
        top: 10.8rem;
        z-index: 10;
        background: white;
        margin-left: -1rem;
        padding: 1rem;
        .searcher {
            background: var(--Gris, #D4D4D4);
        }
        &-navegation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 3rem;
            margin-bottom: 2.4rem;
            &-button {
                display: block;
                position: relative;
                border-radius: 0.71319rem;
                background: var(--blanco-60, rgba(255, 255, 255, 0.60));
                backdrop-filter: blur(5.705317497253418px);
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.625rem;
                font-weight: 400;
                padding: 1.44rem .75rem .44rem .56rem;
                border: 0;
                outline: 0;
                cursor: pointer;
                text-align: left;
                width: 11rem;
                min-height: 6.4rem;
                .arrow-deco {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                span {
                    display: block;
                    color: var(--Negro, #000);
                    font-family: "PP Mori", sans-serif;
                    font-size: 1.125rem;
                    font-weight: 400;
                    line-height: normal;
                    white-space: break-spaces;
                    margin-bottom: .3rem;
                }
                i {
                    position: absolute;
                    top: -.7rem;
                    border-radius: 0.5rem;
                    padding: .12rem .44rem;
                    display: block;
                    svg {
                        path {
                            stroke: white;
                        }
                    }
                    &.button-flipper{
                        background: #000000;
                    }
                    &.button-newapp {
                        background: #9C77FF;
                    }
                    &.button-appgenerator {
                        background: #FF70FF;
                    }
                    &.button-superapps {
                        background: #B2DF53;
                    }
                }
                &:hover {
                    i {
                        animation: bounce 0.5s ease infinite;
                    }
                }
            }
        }
    }
    &::after {
        content: "";
        position: fixed;
        bottom: 100%;
        left: 33px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #ffffff;
        top: 9.891rem;
        z-index: 9999;
      }
}


@media (min-width: 768px) {
    .explore {
        width: calc(100% - 15.6rem);
        padding: 0;
        background-image: url("../../assets/newDesign/backgrounds/explorebg.png");
        backdrop-filter: blur(5px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        &-container {
            padding: 0 2rem 2rem 2rem;
            display: flex;
            flex-direction: column;
        }
        &-header {
            position: relative;
            align-self: center;
            top: 0;
            z-index: 0;
            margin: 0;
            width: 100%;
            padding: 3rem 8rem;
            border-radius: 0.625rem;
            background: var(--blanco-60, rgba(255, 255, 255, 0.60));
            backdrop-filter: blur(5px);
            margin-bottom: 2rem;
            max-width: 71rem;
            h1 {
                color: #000;
                font-family: "PP Mori", sans-serif;
                font-size: 3.875rem;
                font-weight: 100;
                margin-bottom: 1rem;
            }
        }
        &.nomenu-padding {
            .explore-container {
                padding-top: 25rem;
                padding-bottom: 4rem;
            }
        }
        
    }
    .explore-category-buttons {
        justify-content: flex-end;
    }
    .explore::after {
        display: none;
    }
    .showAllApps-apps-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1.88rem;
        .showAllApps-apps-card {
            margin: 0;
            padding: 0;
        }
    }
}

@keyframes bounce {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(.5rem);
    }
  }
  
