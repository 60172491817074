.new-app-btn {
    background-color: var(--primary);
    border: 1px solid #000000;
    border-radius: 2.3rem;
    color: white !important;
    display: flex;
    width: auto;
    height: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 11px 47px;
    align-items: center;
    max-height: 2.5rem;
    svg {
        margin-right: 8px;
    }
}