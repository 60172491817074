.new-workflow {
    width: 100%;
    padding: 0rem 4rem 10rem 4rem;
    background-image: url("../../../assets/newDesign/backgrounds/createbg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &-content {
        &-header {
            border-radius: 1.875rem;
            background: #EEE;
            padding: 2.6rem 2.12rem 3.12rem 2.12rem;
            margin-bottom: 2.06rem;
            h1 {
                color: #000;
                font-size: 2rem;
                font-weight: 500;
                margin-bottom: .7rem;
            }
            p {
                color: #5A5A5A;
                font-size: 0.875rem;
                font-weight: 400;
            }
        }
        &-body {
            &-form {
                padding: 0rem 6rem;
                &-section {
                    margin-bottom: 4rem;
                    h2 {
                        color: #000;
                        font-size: 0.875rem;
                        font-weight: 600;
                        padding: .5rem 1.12rem;
                        border-radius: 0.9375rem;
                        background: #D4D2D2;
                        margin-bottom: .87rem;
                    }
                    .field {
                        display: flex;
                        margin-bottom: 1.06rem;
                        padding-left: 1.12rem;
                        gap: 3rem;
                        &.center-content {
                            align-items: center;
                        }
                        .textarea {
                            min-width: auto;
                        }
                        label {
                            color: #000;
                            font-family: Manrope, sans-serif;
                            font-size: 0.875rem;
                            font-weight: 600;
                            min-width: 7rem;
                        }
                        .input, .textarea {
                            border-radius: 0.9375rem;
                            border: 1px solid #C3C3C3;
                            background: var(--Blanco, #FFF);
                            font-size: 0.875rem;
                            &::placeholder {
                                color: #5A5A5A;
                                font-size: 0.875rem;
                                font-weight: 500;
                            }
                        }
                    }
                    &-load-more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        margin-top: 2rem;
                        
                        button {
                            width: 100%;
                            border-radius: 1.2rem;
                            border: 1px solid rgba(90, 90, 90, 0.85);
                            background: rgba(90, 90, 90, 0.85);
                            color: #FFF;
                            font-size: 0.9375rem;
                            font-weight: 500;
                        }
                    
                    }                    
                }
                &-submit {
                    margin-bottom: 2rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p {
                        color: #181818;
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    &-buttons {
                        display: flex;
                        gap: 1rem;
                        button {
                            border-radius: 1.2rem;
                            border: 1px solid rgba(90, 90, 90, 0.85);
                            background: rgba(90, 90, 90, 0.85);
                            color: #FFF;
                            font-size: 0.9375rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    &-card {
        display: flex;
        padding: 1.25rem 1.06rem .8rem 2.3rem;
        border-radius: 0.625rem;
        border: 1px solid rgba(51, 51, 51, 0.20);
        background: linear-gradient(150deg, rgba(255, 255, 255, 0.60) -20.3%, rgba(241, 240, 240, 0.00) 108.46%);
        margin-bottom: 2.19rem;
        &.is-active {
            box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.1);
        }
        &-left {
            flex: 1;
            h2 {
                color: #333;
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: .6rem;
                background: none;
                padding: .6rem 0;
            }
            .app-by {
                color: #181818;
                font-size: 1rem;
                font-weight: 400;
            }
            .app-information {
                color: #181818;
                font-size: 0.8125rem;
                font-weight: 400;
            }
            p {
                font-size: 0.75rem;
            }
        
            &-buttons {
                margin-top: 1.25rem;
                display: flex;
                gap: .5rem;
                align-items: center;
                button {
                    border: 0;
                    box-shadow: none;
                }
                .show-info {
                    border-radius: 1.25rem;
                    background: rgba(90, 90, 90, 0.85);
                    padding: 0;
                    width: 2.25rem;
                    height: 2.25rem;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .show-prompt {
                    border-radius: 0.9375rem;
                    background: rgba(90, 90, 90, 0.85);
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    color: #FFF;
                    font-size: 0.9375rem;
                    font-weight: 500;
                    padding: .5rem .8rem;
                }
                .add-app {
                    width: 40px;
                    height: 40px;
                    padding: 0;
                }
                .delete-app {
                    background-color: #727272;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    padding: 0;
                    svg {
                        fill: #FFF;
                        path {
                            fill: #FFF;
                        }
                    }
                }
            }
        }
        &-right {
            flex: 1;
            overflow-y: scroll;
            max-height: 10rem;
            .field {
                flex-direction: column;
                gap: 0;
                display: flex;
                label {
                    color: #000;
                    font-size: 0.75rem;
                    font-weight: 600;
                    margin-left: .8rem;
                }
                input, select, .input {
                    border-radius: 0.3125rem;
                    border: 0.684px solid #C3C3C3;
                    background: #FFF;
                    padding: .5rem .8rem;
                    font-size: 0.75rem;
                    &::placeholder {
                        color: #5A5A5A;
                        font-size: 0.75rem;
                        font-weight: 500;
                    }
                    option {
                        color: #5A5A5A;
                        font-size: 0.75rem;
                        font-weight: 500;
                    }
                }
                select {
                    min-height: 35px;
                }            
            }
            &-upload-button {
                display: flex;
                padding: .5rem .8rem;
                align-items: center;
                border-radius: 15px;
                background: #C7C7C7;
            }
            &-file-uploaded {
                padding: .5rem .8rem;
                font-size: 0.75rem;                
            }                   
        }
    }
    .confirmation-dialog-content {
        h2 {
            background: transparent;
            max-width: 22rem;
            text-align: center;
            margin-bottom: 1.44rem;
        }
        .confirmation-dialog-buttons {
            .confirm {
                border-radius: 0.9375rem;
                background: #333;
                font-size: 0.8125rem;
                min-width: 5rem;
            }
            .cancel {
                border-radius: 0.9375rem;
                border: 1px solid #000;
                font-size: 0.8125rem;
                min-width: 5rem;
                background: #FFFFFF;
            }
        }
    }
}

@media (max-width: 768px) {
    .new-workflow {
      padding: 1rem 1.7rem;
    }
    .new-workflow-content-body-form-section .field{
        gap: .5rem;
        flex-wrap: wrap;
    }
}