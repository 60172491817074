.live-app-modal {
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    padding: 1.3rem 1.8rem;
    max-width: 29rem;
    z-index: 99;
    h1, h2 {
        border-radius: 0.9375rem;
        background: #D4D2D2;
        padding: .5rem 1.12rem;
        color: #333;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.88rem;
    }
    .modal-close {
        top: 22px;
        right: 35px;
    }
    &-content {
        padding: 0 1.15rem;
        p {
            margin-bottom: 1.15rem;
            color: #000;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1.25rem;
            &:nth-of-type(3) {
                text-align: center;
            }
        }
    }
    &-form {
        &-buttons {
            display: flex;
            justify-content: center;
            margin-top: 1.8rem;
            gap: 2rem;
        }
    }
    &-submit {
        border-radius: 2.3125rem;
        border: 0 !important;
        background: #BFBFBF !important;
        color: #FFF !important;
        font-size: 0.875rem;
        font-weight: 500;
        padding: .5rem 1.25rem;
    }
    &-cancel {
        border-radius: 2.3125rem;
        border: 0 !important;
        background: #000 !important;
        color: #FFF !important;
        font-size: 0.875rem;
        font-weight: 500;
        padding: .5rem 1.25rem;
    }
}