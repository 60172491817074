.register {
    &-submit {
        border: 0 !important;
        margin-top: 1.24rem;
    }
    &-title {
        margin-bottom: 1.25rem !important;
    }
    &-modal {
        &-google-submit {
            width: 100%;
            background-color: white !important;
            color: black !important;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
            border-radius: 0.88875rem;
            color: #000;
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1rem;

            img.google-logo {
                margin-right: 10px;
                width: 20px;
                height: 20px;
            }
        }
    }
    &-post {
        position: absolute;
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%) !important;
        padding: 1.88rem 3.88rem 3.88rem 3.88rem;
        border-radius: 1rem;
        z-index: 1;
        &-modal {
            &-header {
                padding-bottom: 0.25rem;
                border-bottom: 1px solid rgba(195, 195, 195, 0.80);
                display: flex;
                justify-content: space-between;
                align-self: center;
                .header-cancel {
                    background: rgba(135, 135, 135, 0.45);
                    padding: 0.5rem 1rem;
                    border-radius: 3rem;
                }
                h1 {
                    color: #000;
                    font-family: Manrope, sans-serif;
                    font-size: 1.25rem;
                    font-weight: 500;
                }
                margin-bottom: 1.81rem;
            }
            &-body {
                .field {
                    .radio {
                        color: #5A5A5A;
                        font-size: 1rem;
                        font-weight: 500;
                        display: flex;
                        gap: 1.75rem;
                        margin-bottom: 1rem;
                    }
                }
            }
            &-continue-button {
                width: 100%;
                border-radius: 0.9375rem;
                background: #333;
                color: #FFF !important;
                font-size: 0.875rem;
                font-weight: 600;
                padding: .5rem;
                height: auto;
                margin-top: .5rem;
            }
        }
        input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #5A5A5A;
            border-radius: 50%;
            outline: none;
            position: relative;
          }
          
          input[type="radio"]::before {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          
          input[type="radio"]:checked::before {
            background-color: #5A5A5A;
          }
          
    }
    .slider-indicator {
        display: flex;
        gap: .87rem;
        justify-content: center;
        margin-bottom: 2.44rem;
    }
    .indicator {
        width: .5rem;
        height: .5rem;
        background-color: #ccc;
        border-radius: 50%;
    }
    
    .active {
        background-color: #333333;
    }
}

@media (min-width: 768px)  {
    .register-modal {
        padding: 2rem 8rem !important;
        margin: 0 20px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}