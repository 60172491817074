.newTeam {
    padding: 16rem 1rem 9rem 1rem;
    &-header {
        background: white;
        position: fixed;
        width: 100%;
        top: 10.8rem;
        z-index: 10;
        background: white;
        margin-left: -1rem;
        padding: 1rem;
        .searcher {
            background: var(--Gris, #D4D4D4);
        }
    }
    &-sort {
        display: flex;
        align-items: center;
        gap: 2rem;
    }
    &-columns {
        flex-wrap: wrap;
        align-items: stretch;
    }
    &-header {
        display: flex;
        .control {
            margin-right: 2rem;
        }
    }
    &-subtitle {
        font-size: 1.125rem;
        margin-bottom: 1rem;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        &-button {
            border-radius: 1.25rem;
            background: var(--Negro, #000);
            padding: .62rem;
            color: var(--Blanco, #FFF);
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            border-radius: 1.5rem;
            border: 0;
        }
    }
    &-teams {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        margin: 0;
        span {
            font-size: 0.875rem;
            font-weight: 600;
        }
        label {
            margin: 0 .6rem;
        }
    }
    &-template {
        padding-bottom: 4rem;
        border-bottom: 1px solid rgba(195, 195, 195, 0.80);
        h2 {
            color: #FF42FF;
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }
    }
    &-team {
        margin-bottom: 2rem;
        h2 {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 1rem;
            text-transform: capitalize;
        }
        .appCard {
            border: 1px solid rgba(144, 2, 255, 0.40);
        }
    }
    &-category-buttons {
        margin-top: 1rem;
        margin-bottom: 2rem;
        gap: .88rem;
        display: flex;
        flex-wrap: wrap;
        color: var(--Negro, #000);
        font-family: PP Mori, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 100;
        label {
            font-weight: 600;
        }
        &-button {
            cursor: pointer;
        }
        &-down {
            transform: rotate(180deg);
        }
    }
    &-primary-buttons {
        width: 100%;
        margin-bottom: 22px;
        .newTeam-primary-container-buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 28px;
        }
        h1 {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        a {
            padding: 25px;
            display: flex;
            flex-direction: column;
            flex: 1;
            background: linear-gradient(139deg, #FFF 40.5%, #d6d6d6 100%);
            text-align: center;
            align-items: center;
            color: #000 !important;
        }
        .new-app-border {
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.40);
        }
        .generator-border {
            border-radius: 15px;
            border: 1px solid rgba(144, 2, 255, 0.40);
        }
        .template-border {
            border-radius: 15px;
            border: 1px solid rgba(255, 66, 255, 0.40);
        }
    }
    .header-left {
        width: 100%;
        form {
            max-width: 100%;
            margin: 0;
        }
    }
    .header {
        border-bottom: 0;
    }
    &-most-used {
        margin-bottom: 2.5rem;
    }

    &::after {
        content: "";
        position: fixed;
        bottom: 100%;
        right: 33%;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #ffffff;
        top: 9.891rem;
        z-index: 9999;
      }
      &-missing {
        h2 {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 1.25rem;
        }
        p, ul {
            color: var(--NEGRO, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
        }
        &-btn {
            display: block;
            border-radius: 1.25rem;
            background: var(--Negro, #000);
            color: var(--Blanco, #FFF) !important;
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            padding: .62rem;
            line-height: normal;
            border: 0;
            height: auto;
            max-width: 11.25rem;
            margin-top: 1.25rem;
        }
      }
}

@media (min-width: 768px) {
    .newTeam {
        width: calc(100% - 15.6rem);
        padding: 0;
        &-container {
            padding: 1rem;
        }
        &-header {
            position: relative;
            margin: 0;
            top: 0;
            background: transparent;
            padding: 0;
            z-index: 1;
        }
        &.nomenu-padding {
            .explore-container {
                padding-top: 25rem;
                padding-bottom: 4rem;
            }
        }
        &::after {
            display: none;
        }
    }
    .newTeam-category-buttons {
        justify-content: flex-end;
    }
}
