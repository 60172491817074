.searcher {
    width: 100%;
    border-radius: 1.25rem;
    background: var(--Gris-claro, #E8E6EA);
    position: relative;
    margin-bottom: 1rem;
    .control{
        display: flex;
        align-items: center;
        padding-left: 1rem;
    }
    input {
        border: 0;
        box-shadow: none;
        border-radius: 37px;
        background: transparent;
        outline: none;
        color: var(--negro-45, rgba(0, 0, 0, 0.45));
        font-size: 1rem;
        &:focus, :active, :visited {
            border: 0;
            outline: none;
            box-shadow: none;
        }
    }
}