.voice-input {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    background: linear-gradient(301deg, rgba(241, 240, 240, 0.50) -19.36%, rgba(255, 255, 255, 0.50) 102.8%);
    backdrop-filter: blur(10px);
    position: relative;
    border: 1px solid #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    button {
        background: none;
        border: none;
        cursor: pointer;
        padding: .6rem;
    }
    textarea {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 10px;
        outline: none;
        resize: none;
        height: 135px;
        background: transparent;
    }
    .recording {
        width: 100%;
        &-animation {
            height: 131px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-cancel {
            border-radius: 10px 5px 5px 10px;
        }
        &-buttons {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #E591E5;
            button {
                padding: .6rem;
                svg {
                    height: 21px;
                }
            }
        }
    }
    
    .final {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2.7rem 5rem;
    }
    
    img {
        max-width: 100%;
        height: auto;
    }
    
    .voice-input button img {
        max-width: 25px;
    }
    
    .recording img {
        max-width: 5rem;
    }
    &-final-play {
        display: flex;
        border-radius: 0.79169rem;
        border: 0.844px solid var(--Blanco, #FFF);
        background: var(--Gris-claro, #E8E6EA);
        align-self: center;
        align-items: center;
        overflow: hidden;
        gap: .25rem;
        padding-right: .9rem;
        padding: .5rem;
        width: 100%;
        &-button {
            border-right: 1px solid #FFF !important;
        }
        &-image {
            padding: 0 2rem;
        }
        audio {
            display: none;
        }
    }
}

.voice-input-final-pause-button {
    border-right: 1px solid #FFF !important;
    svg {
        max-height: 1rem;
        max-width: 1rem;
        path {
            stroke: #FF70FF;
        }
    }
}
.recording-play-icon {
    svg {
        path {
            stroke: black !important;
        }
    }
}
.pink-icon {
    svg {
        path {
            stroke: #FF70FF;
        }
    }
}




