.navMobile {
    width: 100%;
    &-tab {
        display: flex;
        justify-content: space-between;
        padding: 0 1.38rem;
        &-item {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 700;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .25rem;
            &-icon {
                height: 47px;
                padding: .75rem;
                background: rgba(255, 255, 255, 0.60);
                border-radius: 50%;
                svg {
                    opacity: 45%;
                }
            }
            &.active {
                svg {
                    opacity: 1;
                }
            }
        }
    }
    &.bottom {
        position: fixed;
        bottom: 0;
        padding-top: 1rem;
        padding-bottom: 2rem;
        background: var(--Blanco, #FFF);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
        .navMobile-tab-item-icon {
            background: var(--Blanco, #FFF);
            box-shadow: none !important;
            padding: 0;
            height: auto;
        }
    }
}
.community.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        fill: #9C77FF;
    }

}

.recent.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #B2DF53;
    }
}

.menuTeam.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        fill: #83BCFF;
    }
}

.menuNews.active {
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        fill: #FF70FF;
    }
}

.menuGenerator.active {
    color : #FF70FF;
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #FF70FF;
    }
}

.menuTemplate.active {
    color : #FF70FF;
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #FF70FF;
    }
}

.menuWorkflow.active {
    color : #FF70FF;
    .navMobile-tab-item-icon {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    }
    path {
        stroke: #FF70FF;
    }
}