.lookerDash {
    width: 100%;
    padding: 1rem 1rem 1rem;
    &-content {
        margin-bottom: 2rem;
        .fullscreen-iframe {
            width: 75vw;
            height: 90vh;
            border: none;
            position: relative;
            top: 1vw;
            left: 2vw;
        }        
    }      
}

@media (min-width: 768px) {
  .lookerDash {
      width: calc(100% - 15.6rem);
      padding: 0;
      &-content {
          padding: 0 2rem 2rem 2rem;
      }
  }
}
