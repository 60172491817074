.apikey {
    width: 100%;
    &-title {
        color: #000;
        font-family: "PP Mori", sans-serif;
        font-size: 2.25rem;
        font-weight: 400;
        margin-bottom: 1.88rem;
    }
    &-content {
        padding: 2rem 4rem;
    }
    h2 {
        color: #5A5A5A;
        font-size: 0.875rem;
        font-weight: 400;
        max-width: 49.4375rem;
    }
    &-information {
        margin-top: 2.69rem;
        &-addnew {
            cursor: pointer;
            border-radius: 0.3125rem;
            background: #E1E0E0;
            display: inline-flex;
            padding: 0.4375rem 1.0625rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            color: #000;
            font-size: 0.875rem;
            font-weight: 500;
            border: 0;
            margin-top: 3.25rem;
        }
    }
    &-table {
        width: 100%;
        background: transparent;
        th {
            color: #000;
            font-size: 0.875rem;
            font-weight: 600;
        }
        td {
            color: #000;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
        }
        &-actions {
            display: flex;
            align-items: center;
            gap: .9rem;
            &-button {
                border: 0;
                background: transparent;
                padding: 0;
                cursor: pointer;
                svg {
                    width: .75rem;
                }
            }
        }
    }
}


.modal-apikey-body {
    position: absolute;
    padding: 1.94rem 3rem;
    border-radius: 0.9375rem;
    border: 1px solid rgba(195, 195, 195, 0.80);
    background: linear-gradient(132deg, #FFF -0.21%, #F1F0F0 108.09%);
    box-shadow: 15px 19px 54px 0px rgba(0, 0, 0, 0.10), 60px 78px 98px 0px rgba(0, 0, 0, 0.09), 134px 175px 133px 0px rgba(0, 0, 0, 0.05), 239px 312px 157px 0px rgba(0, 0, 0, 0.01), 373px 487px 172px 0px rgba(0, 0, 0, 0.00);
    h1 {
        color: #000;
        font-size: 1.25rem;
        font-weight: 500;
        text-transform: capitalize;
        border-bottom: 1px solid rgba(195, 195, 195, 0.80);
        margin-bottom: 1.9rem;
    }
    &-field {
        label {
            color: #5A5A5A;
            font-size: 0.9375rem;
            font-weight: 500;
            margin-bottom: 0.71rem;
        }
        input {
            border-radius: 0.9375rem;
            border: 1px solid #C3C3C3;
            background: #FFF;
            min-width: 28.4375rem;
        }
    }
    &-buttons {
        display: flex;
        gap: 0.75rem;
        margin-top: 2rem;
        &-cancel {
            border-radius: 0.9375rem;
            background: linear-gradient(0deg, #B5B5B5 0%, #B5B5B5 100%), #333;
            color: #FFF;
            font-size: 0.875rem;
            font-weight: 600;
            padding: 0.44rem 0.54rem;
            border: 0 !important;
        }
        &-create {
            color: #FFF;
            padding: 0.44rem 0.54rem;
            font-size: 0.875rem;
            font-weight: 600;
            border-radius: 0.9375rem;
            background: #333;
            border: 0 !important;
        }
    }
}

@media (min-width: 768px) {
    .apikey {
        width: calc(100% - 15.6rem);
        padding: 0;
        &-content {
            padding: 0 2rem 2rem 2rem;
        }
    }
  }

.api-docs-link {
    display: inline-flex;
    align-items: center;
    color: grey;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 10px 20px;
    border: 2px solid grey;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.api-docs-link:hover {
    background-color: grey;
    color: white;
}

.api-docs-icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    fill: currentColor;
    transition: fill 0.3s ease;
}
