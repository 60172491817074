.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1.33356rem;
    border: 1px solid #FFF;
    background: var(--Blanco, #FFF);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 1.1rem 3rem 1.5rem 3rem;
    z-index: 1000;
    .confirm {
        color: var(--Negro, #000);
        text-align: center;
        font-family: Manrope, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        padding: .62rem;
        border-radius: 1.25rem;
        border: 1px dashed var(--Negro, #000);
    }
    .cancel {
        padding: .62rem;
        border-radius: 1.25rem;
        background: var(--Negro, #000);
        color: var(--Blanco, #FFF);
        font-family: Manrope, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
    }
  }

.confirmation-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation-dialog-content h2 {
    color: var(--Negro, #000);
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    margin-bottom: 1.6rem;
    padding-bottom: 0 !important;
}

.confirmation-dialog-buttons {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    gap: 1.25rem;
}
