:root {
    --black: #000;
    --white: #fff;
    --gray: #666;
    --blue: #007BFF;
    --code-bg: #1e1e1e;
}

.useApp {
    padding: 2rem 4rem 0 4rem;
    width: 100%;
    background-image: url("../../assets/newDesign/backgrounds/createbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &-content {
        background-size: cover;
        min-height: 86.8vh;
        padding: 3rem 4rem 4rem 4rem;
        &-container {
            overflow-x: hidden;
            border-radius: 1.25rem;
            padding: 0 .5rem;
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .card-header-badge {
                &::after {
                    margin-left: -15px;
                }
            }
        }
        &-main {
            padding: 0 3rem;
        }
        h1 {
            color: #000;
            font-family: PP Mori, sans-serif;
            font-size: 2.25rem;
            font-weight: 400;
        }
        p {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.9rem;
            font-weight: 400;
        }
        &-username {
            margin-bottom: 1.32rem;
        }

        &-actions {
            display: flex;
            gap: .77rem;
            align-items: center;
            button {
                border: 0;
                background: transparent;
                padding: 0;
                outline: none !important;
                cursor: pointer;
            }
        }
        &-more-info{
            width: calc(100% - 1rem);
            &.border-botom{
                border-bottom: 1px solid var(--negro-45, rgba(0, 0, 0, 0.45));
            }
            &-button {
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.625rem;
                font-weight: 700;
                width: 100%;
                display: flex;
                align-items: center;
                border: 0;
                background: transparent;
                outline: none;
                cursor: pointer;
                padding: .38rem 0;
                &.open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            p {
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.9rem;
                font-weight: 400;
                margin-bottom: .6rem;
            }
        }
        &-logic {  
            border-top: 1px solid var(--negro-45, rgba(0, 0, 0, 0.45));;            
            margin-top: 2rem;
            max-height: 40vh;
            overflow: scroll;
            margin-bottom: 2rem;

            h1, h2 {
                width: 100%;
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 1.75rem !important;
                font-weight: 200 !important;
                max-width: 100% !important;
                margin-bottom: 0.75rem !important;
            }
            p {
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.9rem;
                font-weight: 400;
            }
            &-copy {
                border-radius: 37px;
                border: 1px solid #000;
                background: #FAFAFA;
                padding: 0.3rem 1rem;
                color: #000;
                font-size: 0.8rem;
                margin-top: 1rem;
                cursor: pointer;
            }
            &-actions {
                display: flex;
                gap: 1rem;
                align-items: baseline;
                margin-bottom: 1rem;
            }
            &-download {
                display: flex;
                align-items: center;
                
                &-select {
                    border-radius: 37px;
                    border: 1px solid #000;
                    background: #FAFAFA;
                    padding: 0.3rem 1rem;
                    padding-right: 2rem;
                    background-position: calc(100% - 0.5rem) center;        
                    background-attachment: fixed;            
                    color: #000;
                    font-size: 0.8rem;
                    cursor: pointer;
                    appearance: none;
                    &:disabled {
                        background: #E0E0E0;
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                }
            }
            em {
                font-style: italic;
                font-weight: bold;
                color: #333;
            }
            li {
                font-style: normal;
                font-weight: 300;
                font-size: 0.8rem;
                color: var(--black);
                margin-bottom: 8px;
            }
            
            ul {
                list-style-type: disc;
                padding-left: 20px;
            }
            
            code {
                font-family: monospace;
                padding: 4px;
                color: red;
            }
            
            pre > code {
                font-size: 0.8rem;
                background-color: var(--code-bg);
                opacity: 90%;
                display: block;
                white-space: pre-wrap;
                color: var(--white);
            }
            
            pre {
                position: relative;
                background-color: var(--code-bg);
                opacity: 85%;
                padding: 10px;
                margin-bottom: 16px;
                border-radius: 16px;
                color: var(--white);
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 2rem;
                margin-bottom: 2rem;
                font-size: 0.8rem;
                color: var(--gray);
                background: rgba(250, 250, 250, 0.84);
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.20), 4px 5px 14px 0px rgba(0, 0, 0, 0.20);
                border-radius: 15px;
                overflow: hidden;
            }
            caption {
                font-size: 1rem;
                color: var(--blue);
                margin-bottom: 1rem;
                text-align: center;
            }

            thead {
                background: var(--blue);
                color: var(--white);
            }
            
            th {
                padding: 0.8rem;
                text-align: left;
                font-weight: bold !important;
                background-color: #f1f1f1;
            }
            
            td {
                padding: 0.8rem;
                text-align: left;
                border-bottom: 1px solid #ddd;
            }
            
            tr:nth-child(even) {
                background-color: var(--light-gray);
            }
            
            tr:hover {
                background-color: #f5f5f5;
            }
            &-copy-code {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: rgba(255, 255, 255, 0.8);
                color: #333;
                border: none;
                border-radius: 20px;
                padding: 6px 12px;
                font-size: 12px;
                cursor: pointer;
                z-index: 10;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                transition: background-color 0.3s ease, box-shadow 0.3s ease;
                
                &:hover {
                    background-color: rgba(200, 200, 200, 1);
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                }

                &:active {
                    background-color: rgba(200, 200, 200, 1);
                }                
            }
            
            
        }
    }
    &-left-content {
        &-showprompt {
            margin-top: 1.5rem;
            border-radius: 1rem;
            border: 1px solid rgba(90, 90, 90, 0.85);
            background: rgba(90, 90, 90, 0.85);
            color: #FFF;
            font-size: 0.9375rem;
            font-weight: 500;
            padding: 0.3rem 1.5rem;
            display: flex;
            align-items: center;
            svg {
                margin-right: 0.5rem;
            }
        }
        &-heart {
            position: relative;
            top: unset; 
            right: unset;
        }
        &-logic {
            font-size: 1rem !important;
            user-select: none;
        }
        .field {
            display: flex;
            align-items: center;
            gap: .5rem;
            margin-top: 1rem;
            .select {
                border-radius: 15px;
                border: 1px solid #C3C3C3;
                background: #FFF;
                padding: 0rem 0.5rem;
                color: #5A5A5A;
                font-size: 0.875rem;
            }
        }
    }
    &-form {
        margin-top: 1rem;
        label {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.8rem;
            font-weight: 700;
        }
        input, select {
            border-radius: 0.625rem;
            border: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45));
            background: var(--blanco-60, rgba(255, 255, 255, 0.60));
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.8rem;
            font-weight: 300;
            padding: 0.31rem .62rem !important;
            &::placeholder {
                color: var(--negro-45, rgba(0, 0, 0, 0.45));
                font-family: Manrope, sans-serif;
                font-size: 0.8rem;
                font-weight: 300;
            }
        }
        .field {
            margin-bottom: .62rem;
        }
        &-buttons {
            display: flex;
            justify-content: flex-end;
            gap: .62rem;
            margin-top: 1.31rem;
        }        
        &-submit {
            border-radius: 1.25rem;
            border: 0;
            background: var(--Negro, #000);
            padding: .62rem;
            color: var(--Blanco, #FFF);
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            &:hover {
                color: #FFF;
            }
        }
        &-clear {
            border-radius: 1.25rem;
            border: 0;
            background: var(--Blanco, #FFF);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            padding: .62rem;
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            cursor: pointer;
        }
        
        &-upload-button {
            display: flex;
            height: 31px;
            padding: 0px 183px;
            align-items: center;
            gap: 9px;
            flex-shrink: 0;
            width: 100%;
            border-radius: 15px;
            background: #C7C7C7;
            color: #000;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 2rem;
        }
        &-file-uploaded {
            margin-bottom: 2rem;
        }
        &-remove-file {
            background-color: #d3d3d3;
            color: #000000;
            border-radius: 50%;
            border: none;
            width: 20px;
            height: 20px;
            font-size: 12px;
            cursor: pointer;
            outline: none;
            margin-top: 3px;
            &:hover {
              background-color: #ff0000;
              color: #ffffff;
            }
        }
    }
    .model {
        margin-top: 1rem;
    }
}
.disclaimer {
    font-size: 14px !important;
    border: 1px solid #ccc;
    margin-top: 2rem;
    padding: 10px !important;
    background-color: #f0f0f0 !important;
}

.widget {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: auto;
    padding: 20px;
    color: #333333;
    font-family: 'Arial', sans-serif;
    
    h1, h2 {
      font-size: 28px;
      margin-bottom: 15px;
    }
  
    h3 {
      font-size: 22px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  
    label {
      font-size: 16px;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  
    input[type="radio"] {
      margin-right: 5px;
      vertical-align: middle;
    }
  
    button {
      background-color: #4CAF50;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 20px;
  
      &:hover {
        background-color: #45a049;
      }
    }
  
    #result, #output {
      margin-top: 20px;
      p {
        margin-bottom: 10px;
      }
    }
  }

@media (min-width: 768px)  {
    .useApp-columns {
        flex-direction: row;
    }
}
#toggleNodesBtn {
    display: block;
    border-radius: 37px;
    border: 1px solid var(--gray);
    background: var(--white);
    padding: 0.4rem 1.2rem;
    color: var(--black);
    font-size: 0.8rem;
    margin: 2rem 0;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: var(--gray);
        color: var(--white);
    }
}
.source-nodes-container {
    background: rgba(240, 240, 240, 0.95);
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 3rem;
    padding: 1rem;
    .source-nodes-header {
        padding: 1rem;
        background-color: #f7f7f7;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        margin-bottom: 1.5rem;
        h3 {
            color: #333;
            font-size: 1.3rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
        p {
            color: #777;
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.4;
            &:last-child {
                color: var(--gray);
                span.highlight {
                    font-weight: bold;
                    color: var(--blue);
                }
            }
        }
        ul {
            padding-left: 1.5rem;
            color: var(--gray);
            li {
                margin-bottom: 0.1rem;
                font-size: 0.9rem;
                span.highlight {
                    font-weight: bold;
                    color: var(--blue);
                }
            }
        }
    }
    .source-node {
        border-bottom: 1px solid var(--gray);
        padding: 0.5rem 0;
        &:last-child {
            border-bottom: none;
        }
        p {
            font-size: 0.9rem;
            color: var(--gray);
            strong {
                color: var(--black);
            }
        }
    }
    .toggle-text-btn {
        background-color: #e9f5ff;
        border: 1px solid #a8d1ff;
        color: #0077cc;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 0.9rem;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;
        margin: 0 10px;
        margin-bottom: 1rem;
        outline: none;
        &:hover {
            background-color: #d4e8ff;
        }
        &:active {
            transform: scale(0.98);
        }
    }
}

.isGenerator {
    background-image: url("../../assets/appGeneratorBg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    header {
        margin-bottom: 0;
        border-bottom: 0;
    }
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none !important;
        border: 0;
        padding: 0 !important;
        background: transparent !important;
        h1 {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif !important;
            font-size: 1.625rem !important;
            font-weight: 600;
            max-width: 100% !important;
            text-align: center;
        }
        p {
            color: var(--Negro, #000);
            text-align: center;
            font-family: "PP Mori", sans-serif !important;
            font-size: 1rem !important;
            font-weight: 400;
            white-space: break-spaces;
        }
    }
    .voice-input {
        margin-top: .6rem;
        border-radius: 1.25rem;
        border-top: 1px solid var(--Blanco, #FFF);
        border-right: 1px solid var(--Blanco, #FFF);
        border-left: 1px solid var(--Blanco, #FFF);
        background: var(--blanco-60, rgba(255, 255, 255, 0.60));
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    }

    .audio-transcript {
        margin-top: 1rem;
        max-width: 400px;
    }
    &-form {
        margin-top: 2.75rem;
        form {
            display: flex;
            flex-direction: column;
        }
        p {
            font-size: 1.071rem;
        }
        &-buttons {
            display: flex;
            gap: .6rem;
            margin-top:2.3rem;
            flex-wrap: wrap;
            button:disabled {
                background-color: #cccccc; 
                color: #666666;
                cursor: not-allowed; 
                border: 1px solid #999999; 
                opacity: 0.5; 
                pointer-events: none;
              }
        }
        &-history {
            display: flex;
            gap: .9rem;
            margin-top: 2.3rem;
            align-items: center;
            label {
                font-size: 0.8125rem;
            }
            .select-container {
                margin-top: 0 !important;
            }
        }
        textarea {
            color: #000;
            font-size: 0.8125rem;
            font-family: Manrope, sans-serif;
        }
        &-result {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 2.3rem;
            a {
                display: block;
                margin-top: .9rem;
                border-radius: 2.3125rem;
                border: 1px solid #242424;
                background: #333;
                color: #FFF;
                font-size: 1rem;
                font-weight: 700;
                padding: .5rem 1.25rem;
            }
        }
        &-buttons {
            display: flex;
            gap: .7rem;
            &-clear {
                cursor: pointer;
                border-radius: 1.25rem;
                background: var(--Blanco, #FFF);
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
                color: var(--Negro, #000);
                text-align: center;
                font-family: Manrope, sans-serif;
                font-size: 0.875rem;
                font-weight: 400;
                padding: .62rem;
                border: 0;
                outline: none;
            }
            &-submit {
                cursor: pointer;
                border-radius: 1.25rem;
                background: var(--Negro, #000);
                color: var(--Blanco, #FFF);
                text-align: center;
                font-family: Manrope, sans-serif;
                font-size: 0.875rem;
                font-weight: 400;
                padding: .62rem;
                border: 0;
                outline: none;
                display: flex;
                align-items: center;
            }
        }
        .message {
            margin-top: 2rem;
            padding: 1rem;
        }
    }
    .custom-select:focus {
        outline: none;
        border: 1px solid #FFF;
    }
    .custom-select {
        padding: 10px;
        -webkit-appearance: none; 
        -moz-appearance: none;
        appearance: none;
        background: linear-gradient(301deg, rgba(241, 240, 240, 0.50) -19.36%, rgba(255, 255, 255, 0.50) 102.8%);
        backdrop-filter: blur(10px);
        cursor: pointer;
        border-radius: 0.625rem;
        padding-right: 30px;
        border: 1px solid #FFF;
        font-size: 0.8125rem;
    }
    .select-container {
        position: relative;
        display: inline-block;
        &::after {
            display: none;
        }
      }
    .select-arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

}

.warning-message {
    color: #946c00;
    font-size: .8rem;
    border-radius: .3rem;
}

@media (max-width: 768px) {
    .useApp {
        padding: 12rem 2rem 8rem 2rem;
        h1 {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 1rem;
            max-width: 11rem;
        }
        &-form {
            padding-right: 1rem;
            margin-top: .62rem;
        }
        &-left-content-showprompt {
            display: none;
        }
    }
    .useApp-content {
        background: transparent;
        border-radius: 1.25rem;
        background: var(--blanco-60, rgba(255, 255, 255, 0.60));
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
        margin: 0;
        padding: .62rem .62rem 4.56rem 3rem;
        min-height: auto;
    }
    .useApp .isGenerator {
        background-image: url("../../assets/appGeneratorBg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media (min-width: 768px)  {
    .useApp {
        width: calc(100% - 15.6rem);
        padding: 0;
        min-height: 100vh;
        &-content {
            margin: 0;
            padding: 2rem 4rem 2rem 4rem;
            min-height: auto;
            h1 {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 1.75rem;
                font-weight: 600;
                margin-bottom: .87rem;
            }
        }
        &-container {
            padding: 1rem;
        }
    }
    .isGenerator-content {
        padding: 2rem !important;
        min-height: calc(100vh - 6.6rem);
    }
    .appGenerator {
        padding: 6rem;
        border-radius: 1.25rem;
        background: var(--blanco-60, rgba(255, 255, 255, 0.35));
        box-shadow: 0 0 15px 15px rgba(255, 255, 255, 0.35);
        h1 {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 3.875rem !important;
            font-weight: 100;
            text-align: left;
            max-width: 19rem !important;
            line-height: 1;
            margin-bottom: 1.5rem;
        }
        p {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem !important;
            font-weight: 400;
            text-align: left;
        }
        .isGenerator-form {
            p {
                color: var(--Negro, #000);
                font-family: Manrope, sans-serif;
                font-size: 0.875rem;
                font-weight: 700;
                margin-bottom: 1rem;
            }
        }
        .message {
            max-width: 35rem;
        }
    }
    .isGenerator .voice-input {
        margin-top: 0;
    }
    .voice-input {
        min-width: 32rem;
    }
    .useApp-content-more-info-button {
        color: var(--Negro, #000);
        font-family: Manrope, sans-serif;
        font-size: 0.9rem;
        font-weight: 700;
    }
}