.useWorkflow {
    padding: 0rem 4rem 0 4rem;
    width: 100%;
    background-image: url("../../../assets/newDesign/backgrounds/createbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &-content {
        background-size: cover;
        min-height: 86.8vh;
        padding: 1rem 4rem 4rem 4rem !important;
        &-container {
            overflow-x: hidden;
            border-radius: 1.25rem;
            padding: 0 .5rem;
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .card-header-badge {
                &::after {
                    margin-left: -15px;
                }
            }
        }
        &-main {
            padding: 0 3rem;
        }
        h1 {
            color: #000;
            font-family: PP Mori, sans-serif;
            font-size: 1.75rem !important;
            font-weight: 200 !important;
        }
        h2 {
            color: #000;
            font-family: PP Mori, sans-serif;
            font-size: 1.4rem;
            font-weight: 200;
            padding-bottom: 1rem;
        }

        p {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.9rem;
            font-weight: 400;
        }
        &-username {
            margin-bottom: 1.32rem;
        }

        &-actions {
            display: flex;
            gap: .77rem;
            align-items: center;
            button {
                border: 0;
                background: transparent;
                padding: 0;
                outline: none !important;
                cursor: pointer;
            }
        }
    }
    &-form {
        margin-top: 3rem !important;
        label {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.625rem;
            font-weight: 700;
        }
        input, select {
            border-radius: 0.625rem;
            border: 0.5px solid var(--negro-45, rgba(0, 0, 0, 0.45));
            background: var(--blanco-60, rgba(255, 255, 255, 0.60));
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.625rem;
            font-weight: 300;
            padding: 0.31rem .62rem !important;
            &::placeholder {
                color: var(--negro-45, rgba(0, 0, 0, 0.45));
                font-family: Manrope, sans-serif;
                font-size: 0.625rem;
                font-weight: 300;
            }
        }
        .field {
            margin-bottom: .62rem;
        }
        &-buttons {
            display: flex;
            justify-content: flex-end;
            gap: .62rem;
            margin: 1.31rem 3rem;
        }        
        &-submit {
            border-radius: 1.25rem;
            border: 0;
            background: var(--Negro, #000);
            padding: .62rem;
            color: var(--Blanco, #FFF);
            text-align: center;
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            &:hover {
                color: #FFF;
            }
        }
        &-clear {
            border-radius: 1.25rem;
            border: 0;
            background: var(--Blanco, #FFF);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            padding: .62rem;
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            cursor: pointer;
        }
        
        &-upload-button {
            display: flex;
            height: 31px;
            padding: 0px 183px;
            align-items: center;
            gap: 9px;
            flex-shrink: 0;
            width: 100%;
            border-radius: 15px;
            background: #C7C7C7;
            color: #000;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 2rem;
        }
        &-file-uploaded {
            margin-bottom: 2rem;
        }
        &-remove-file {
            background-color: #d3d3d3;
            color: #000000;
            border-radius: 50%;
            border: none;
            width: 20px;
            height: 20px;
            font-size: 12px;
            cursor: pointer;
            outline: none;
            margin-top: 3px;
            &:hover {
              background-color: #ff0000;
              color: #ffffff;
            }
        }
    }
    &-card {
        display: flex;
        padding: 1.25rem 1rem;
        border-radius: 0.625rem;
        border: 1px solid rgba(51, 51, 51, 0.20);
        background: linear-gradient(150deg, rgba(255, 255, 255, 0.60) -20.3%, rgba(241, 240, 240, 0.00) 108.46%);
        max-height: 14rem;
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 1rem;
        &-left {
            flex: 1;
            h2 {
                color: #333;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.75rem;
            }
        }
        &-right {
            overflow-y: scroll;
            flex: 1;
            .label {
                color: #000;
                font-size: 0.75rem;
                font-weight: 600;
            }
            input {
                font-size: 0.75rem;
                border-radius: 0.3125rem;
                border: 0.684px solid #C3C3C3;
                background: #FFF;
                width: 100%;
            }
            p {
                font-size: 0.75rem;
            }
        }
    }
    &-results{
        margin: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 1px solid #ddd; /* Added border */
        background: linear-gradient(145deg, #f8f9fa, #e9ecef);
        padding: 1rem;
        border-radius: 8px;
        max-height: 50vh;
        overflow: scroll;
        h1, h2 {
            width: 100%;
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 1rem !important;
            font-weight: 700;
            max-width: 100% !important;
            margin-bottom: 0.75rem !important;
        }
        p {
            color: var(--Negro, #000);
            font-family: Manrope, sans-serif;
            font-size: 0.75rem;
            font-weight: 300;
        }
        &-copy {
            border-radius: 37px;
            border: 1px solid #000;
            background: #FAFAFA;
            padding: 0.3rem 1rem;
            color: #000;
            font-size: 0.8rem;
            margin-top: 1rem;
            cursor: pointer;
        }
        &-actions {
            display: flex;
            gap: 1rem;
            align-items: baseline;
        }
        &-download {
            display: flex;
            align-items: center;
            
            &-select {
                border-radius: 37px;
                border: 1px solid #000;
                background: #FAFAFA;
                padding: 0.3rem 1rem;
                padding-right: 2rem;
                background-position: calc(100% - 0.5rem) center;        
                background-attachment: fixed;            
                color: #000;
                font-size: 0.8rem;
                cursor: pointer;
                appearance: none;
                &:disabled {
                    background: #E0E0E0;
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }
        }
        em {
            font-style: italic;
            font-weight: bold;
            color: #333;
        }
        li {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            color: var(--black);
            margin-bottom: 8px;
        }
        
        ul {
            list-style-type: disc;
            padding-left: 20px;
        }
        
        code {
            font-family: monospace;
            padding: 4px;
            color: red;
        }
        
        pre > code {
            font-size: 16px;
            background-color: var(--code-bg);
            opacity: 90%;
            display: block;
            white-space: pre-wrap;
            color: var(--white);
        }
        
        pre {
            position: relative;
            background-color: var(--code-bg);
            opacity: 85%;
            padding: 10px;
            margin: 10px 10px;
            border-radius: 16px;
            color: var(--white);
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 2rem;
            margin-bottom: 2rem;
            font-size: 0.9rem;
            color: var(--gray);
            background: rgba(250, 250, 250, 0.84);
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.20), 4px 5px 14px 0px rgba(0, 0, 0, 0.20);
            border-radius: 15px;
            overflow: hidden;
        }
        caption {
            font-size: 2rem;
            color: var(--blue);
            margin-bottom: 1rem;
            text-align: center;
        }

        thead {
            background: var(--blue);
            color: var(--white);
        }
        
        th {
            padding: 0.8rem;
            text-align: left;
            font-weight: bold !important;
            background-color: #f1f1f1;
        }
        
        td {
            padding: 0.8rem;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }
        
        tr:nth-child(even) {
            background-color: var(--light-gray);
        }
        
        tr:hover {
            background-color: #f5f5f5;
        }
        &-copy-code {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: rgba(255, 255, 255, 0.8);
            color: #333;
            border: none;
            border-radius: 20px;
            padding: 6px 12px;
            font-size: 12px;
            cursor: pointer;
            z-index: 10;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s ease, box-shadow 0.3s ease;
            
            &:hover {
                background-color: rgba(200, 200, 200, 1);
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            }

            &:active {
                background-color: rgba(200, 200, 200, 1);
            }                
        }
    }
    &-step-results{
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 1px solid #ddd;
        background: linear-gradient(145deg, #f8f9fa, #e9ecef);
        padding: 1rem;
        border-radius: 8px;
        .toggle-intermediate-results {
            background: linear-gradient(190deg, #f8f9fa, #e9ecef);
            font-family: Manrope, sans-serif;
            font-size: 0.75rem;
            font-weight: 300;
            border-top: 1px solid #ddd; /* Separation line */
            border-radius: 4px;
            cursor: pointer;
            font-weight: 600;
            transition: background-color 0.3s ease; /* Smooth transition on hover */
        }    
        .step-detail {
            margin-top: 1rem;
            font-size: 0.75rem !important;
            border: 1px solid #eee;
            background: #ffffff;
            border-radius: 4px;
            max-height: 50vh;
            overflow: scroll;
            cursor: pointer;
        }
    }
    
    .model {
        margin-top: 1rem;
    }
}

@media (min-width: 768px)  {
    .useWorkflow-columns {
        flex-direction: row;
    }
}

.warning-message {
    color: #946c00;
    font-size: .8rem;
    border-radius: .3rem;
}

@media (max-width: 768px) {
    .useWorkflow {
        padding: 12rem 2rem 8rem 2rem;
        h1 {
            color: var(--Negro, #000);
            font-family: "PP Mori", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 1rem;
            max-width: 11rem;
        }
        &-form {
            padding-right: 1rem;
            margin-top: .62rem;
        }
        &-left-content-showprompt {
            display: none;
        }
    }
    .useWorkflow-content {
        background: transparent;
        border-radius: 1.25rem;
        background: var(--blanco-60, rgba(255, 255, 255, 0.60));
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
        margin: 0;
        padding: .62rem .62rem 4.56rem 3rem;
        min-height: auto;
    }
}
@media (min-width: 768px)  {
    .useWorkflow {
        width: calc(100% - 15.6rem);
        padding: 0;
        min-height: 100vh;
        &-content {
            margin: 0;
            padding: 7rem 4rem 2rem 4rem;
            min-height: auto;
            h1 {
                color: var(--Negro, #000);
                font-family: "PP Mori", sans-serif;
                font-size: 1.125rem;
                font-weight: 600;
                margin-bottom: .87rem;
            }
        }
        &-container {
            padding: 1rem;
        }
    }

}