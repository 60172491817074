.badge {
    border-radius: 1.25rem;
    text-align: center;
    padding: .62rem;
    p {
        color: var(--Negro, #000);
        text-align: center;
        font-family: Manrope, sans-serif;
        font-size: 0.875rem;
        font-weight: 300;
        line-height: normal;
    }
    &-general {
        border: 1px solid #000;
        background: var(--Gris-claro, #E8E6EA);
    }
}